import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { intersection } from "../../shared/src/codecs/types/intersection";
import { nullCodec } from "../../shared/src/codecs/types/nullCodec";
import { positiveInteger } from "../../shared/src/codecs/types/positiveInteger";
import { postcode } from "../../shared/src/codecs/types/postcode";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { union } from "../../shared/src/codecs/types/union";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { Address4 } from "./Address";
import { TransactionType1 } from "./TransactionType";
import { CreateUser } from "./User";

export const PublicPropertyReportAddressSearch = required({
    introducer_id: union([nullCodec(), uuid()]),
    deceased_name: string(),
    building_number_name: string(),
    postcode: postcode(),
    transaction_type: union([nullCodec(), TransactionType1]),
    wants_valuation: union([nullCodec(), boolean()]),
    introducer: CreateUser,
    executor_or_beneficiary: intersection([
        CreateUser,
        required({
            is_executor: boolean(),
        }),
    ]),
});
export type TPublicPropertyReportAddressSearchCodec = typeof PublicPropertyReportAddressSearch;

export const PublicPropertyReportTitleSearch = intersection([
    required({
        id: positiveInteger(),
        introducer_id: union([nullCodec(), uuid()]),
        deceased_name: string(),
        transaction_type: union([nullCodec(), TransactionType1]),
        wants_valuation: union([nullCodec(), boolean()]),
        introducer: CreateUser,
        executor_or_beneficiary: intersection([
            CreateUser,
            required({
                is_executor: boolean(),
            }),
        ]),
    }),
    Address4,
]);
export type TPublicPropertyReportTitleSearchCodec = typeof PublicPropertyReportTitleSearch;

export const PublicPropertyReportCreate = intersection([
    required({
        title_number: string(),
    }),
    PublicPropertyReportTitleSearch,
]);
export type TPublicPropertyReportCreateCodec = typeof PublicPropertyReportCreate;
export type TPublicPropertyReportCreate = TTypeOfCodec<TPublicPropertyReportCreateCodec>;
