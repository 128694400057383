import { form, TFormCodec } from "../../../shared/src/codecs/types/form";
import { TTypeOfCodec } from "../../../shared/src/codecs/codec";
import { required, TRequiredCodec } from "../../../shared/src/codecs/types/required";
import { EmptyObject, TEmptyObjectCodec } from "../EmptyObject";
import { DetailedLegalEmailForTriage, SimpleLegalEmailForTriage, SimpleLegalEmailWithPersonsForTriage, TDetailedLegalEmailForTriageCodec, TSimpleLegalEmailForTriageCodec, TSimpleLegalEmailWithPersonsForTriageCodec } from "../LegalEmail";
import { TUuidCodec, uuid } from "../../../shared/src/codecs/types/uuid";
import { string, TAnyStringCodec } from "../../../shared/src/codecs/types/string";
import { array, TArrayCodec } from "../../../shared/src/codecs/types/array";
import { pipe } from "fp-ts/lib/function";
import { array as fptsArray, option } from "fp-ts";
import { Eq as stringEq } from "fp-ts/lib/string";
import { getEq as recordGetEq } from "fp-ts/lib/Record";
import { CaseMemberName, TCaseMemberName } from "../CaseMember";
import { intersection, TIntersectionCodec } from "../../../shared/src/codecs/types/intersection";
import { requiredFlatOverloaded, TRequiredFlatOverloadedCodec } from "../../../shared/src/codecs/types/requiredFlatOverloaded";
import { TUserNameCodec, UserName } from "../overloads/UserName";
import { TUserAssignableToAdhocCodec, UserAssignableToAdhoc } from "../User";
import { TUuidObjectCodec, UuidObject } from "../Id";
import { Address2 } from "../Address";
import { TPagination1Codec, TPagination3Codec, Pagination1, Pagination3 } from "../Pagination";
import { integer, TIntegerCodec } from "../../../shared/src/codecs/types/integer";
import { EmailCompositionForm, TEmailCompositionFormCodec } from "./EmailCompositionForm";
import { literal } from "../../../shared/src/codecs/types/literal";
import { union } from "../../../shared/src/codecs/types/union";
import { boolean, TBooleanCodec } from "../../../shared/src/codecs/types/boolean";
import { partial, TPartialCodec } from "../../../shared/src/codecs/types/partial";
import { TransactionType1 } from "../TransactionType";
import { CaseStatus } from "../Cases";
import { requireExhaustive } from "../../../shared/src/util";
import { CaseUpdateChaseModeForm, TCaseUpdateChaseModeFormCodec } from "./CasesChaseTransitionForm";

export const TriageEmailAssignToCaseForm: TFormCodec<TRequiredCodec<{
    email_id: TUuidCodec,
    case_id: TUuidCodec,
}>, TEmptyObjectCodec> = form(
    required({
        email_id: uuid(),
        case_id: uuid(),
    }),
    EmptyObject,
);
export type TTriageEmailAssignToCaseFormCodec = typeof TriageEmailAssignToCaseForm;
export type TTriageEmailAssignToCaseForm = TTypeOfCodec<TTriageEmailAssignToCaseFormCodec>;

export const TriageEmailAssignToCaseWithAdhocForm: TFormCodec<TRequiredCodec<{
    email_id: TUuidCodec,
    case_id: TUuidCodec,
    user_id: TAnyStringCodec,
    job: TAnyStringCodec,
}>, TRequiredCodec<{
    users: TArrayCodec<TUserAssignableToAdhocCodec>,
}>> = form(
    required({
        email_id: uuid(),
        case_id: uuid(),
        user_id: string(),
        job: string(),
    }),
    required({
        users: array(UserAssignableToAdhoc),
    }),
);
export type TTriageEmailAssignToCaseWithAdhocFormCodec = typeof TriageEmailAssignToCaseWithAdhocForm;
export type TTriageEmailAssignToCaseWithAdhocForm = TTypeOfCodec<TTriageEmailAssignToCaseWithAdhocFormCodec>;

export const TriageEmailAssignToUserOnlyForm: TFormCodec<TRequiredCodec<{
    email_id: TUuidCodec,
    user_id: TUuidCodec,
    expires_seconds: TIntegerCodec,
}>, TRequiredFlatOverloadedCodec<{
    name: TUserNameCodec,
}>> = form(
    required({
        email_id: uuid(),
        user_id: uuid(),
        expires_seconds: integer(),
    }),
    requiredFlatOverloaded({
        name: UserName,
    }),
);
export type TTriageEmailAssignToUserOnlyFormCodec = typeof TriageEmailAssignToUserOnlyForm;
export type TTriageEmailAssignToUserOnlyForm = TTypeOfCodec<TTriageEmailAssignToUserOnlyFormCodec>;

export const TriageEmailAssignToCaseAndUserForm: TFormCodec<TRequiredCodec<{
    email_id: TUuidCodec,
    case_id: TUuidCodec,
    user_id: TUuidCodec,
    expires_seconds: TIntegerCodec,
}>, TRequiredFlatOverloadedCodec<{
    name: TUserNameCodec,
}>> = form(
    required({
        email_id: uuid(),
        case_id: uuid(),
        user_id: uuid(),
        expires_seconds: integer(),
    }),
    requiredFlatOverloaded({
        name: UserName,
    }),
);
export type TTriageEmailAssignToCaseAndUserFormCodec = typeof TriageEmailAssignToCaseAndUserForm;
export type TTriageEmailAssignToCaseAndUserForm = TTypeOfCodec<TTriageEmailAssignToCaseAndUserFormCodec>;

export const ResultTypeForTriage = union([
    literal("already_assigned"),
    literal("reference_matches_assigned_email"),
    literal("email_matches_user_on_case"),
    literal("manual_search")
]);
export type TResultTypeForTriageCodec = typeof ResultTypeForTriage;
export type TResultTypeForTriage = TTypeOfCodec<TResultTypeForTriageCodec>;

export const resultTypeForTriageToDisplay = (type: TResultTypeForTriage) =>
    type === "already_assigned" ? "Currently Assigned to Case"
    : type === "reference_matches_assigned_email" ? "Reply/Forward of Email on Case"
    : type === "email_matches_user_on_case" ? "Email Address Matches Contact"
    : type === "manual_search" ? "Manually Searched"
    : requireExhaustive(type);

export const CasesResultsForTriage = intersection([
    requiredFlatOverloaded({
        address: array(Address2),
    }),
    required({
        id: uuid(),
        result_types: array(ResultTypeForTriage),
        klyant_matter_id: string(),
        contacts: array(CaseMemberName),
        assign_to_case_handlers_form: array(TriageEmailAssignToCaseAndUserForm),
        assign_to_other_staff_form: array(TriageEmailAssignToCaseAndUserForm),
        assign_to_me_form: TriageEmailAssignToCaseAndUserForm,
        resolve_form: TriageEmailAssignToCaseForm,
        resolve_and_create_adhoc_form: TriageEmailAssignToCaseWithAdhocForm,
        status: CaseStatus,
        transaction_type: TransactionType1,
        is_dual_rep: boolean(),
    }),
]);
export type TCasesResultsForTriageCodec = typeof CasesResultsForTriage;
export type TCasesResultsForTriage = TTypeOfCodec<TCasesResultsForTriageCodec>;


export const TriageEmailAssignToCaseSearchForm: TFormCodec<TRequiredCodec<{
    search_term: TAnyStringCodec,
    email_id: TUuidCodec,
}>, TRequiredCodec<{
    results: TArrayCodec<TCasesResultsForTriageCodec>
}>> = form(
    required({
        search_term: string(),
        email_id: uuid(),
    }),
    required({
        results: array(
            CasesResultsForTriage,
        ),
    }),
);
export type TTriageEmailAssignToCaseSearchFormCodec = typeof TriageEmailAssignToCaseSearchForm;
export type TTriageEmailAssignToCaseSearchForm = TTypeOfCodec<TTriageEmailAssignToCaseSearchFormCodec>;
export const TriageEmailAssignToCaseSearchForm_mergeSearchResults = (f: TTriageEmailAssignToCaseSearchForm): TTriageEmailAssignToCaseSearchForm => ({
    ...f,
    children: {
        ...f.children,
        results: pipe(
            f.children.results,
            fptsArray.reduce<TTriageEmailAssignToCaseSearchForm["children"]["results"][number], TTriageEmailAssignToCaseSearchForm["children"]["results"]>([], (acum, result) =>
                pipe(
                    acum,
                    fptsArray.findIndex((p) => p.id === result.id),
                    option.fold(
                        () => [
                            ...acum,
                            result,
                        ],
                        (index) => {
                            acum[index].address = pipe(
                                [
                                    ...acum[index].address,
                                    ...result.address
                                ],
                                fptsArray.uniq(stringEq),
                            );
                            acum[index].contacts = pipe(
                                [
                                    ...acum[index].contacts,
                                    ...result.contacts
                                ],
                                fptsArray.uniq<TCaseMemberName>(recordGetEq(stringEq)),
                            );
                            return acum;
                        }
                    )
                )
            ),
        )
    }
});

export const TriageEmailMarkAsIrrelevantForm: TFormCodec<TRequiredCodec<{ email_id: TUuidCodec }>, TEmptyObjectCodec> = form(
    required({ email_id: uuid() }),
    EmptyObject,
);
export type TTriageEmailMarkAsIrrelevantFormCodec = typeof TriageEmailMarkAsIrrelevantForm;
export type TTriageEmailMarkAsIrrelevantForm = TTypeOfCodec<TTriageEmailMarkAsIrrelevantFormCodec>;

export const TriageEmailMarkAsResolvedForm: TFormCodec<
    TIntersectionCodec<[
        TRequiredCodec<{
            email_id: TUuidCodec
        }>,
        TPartialCodec<{
            case_chase_mode_form: TCaseUpdateChaseModeFormCodec,
        }>
    ]>,
    TEmptyObjectCodec
> = form(
    intersection([
        required({
            email_id: uuid()
        }),
        partial({
            case_chase_mode_form: CaseUpdateChaseModeForm,
        }),
    ]),
    EmptyObject,
);
export type TTriageEmailMarkAsResolvedFormCodec = typeof TriageEmailMarkAsResolvedForm;
export type TTriageEmailMarkAsResolvedForm = TTypeOfCodec<TTriageEmailMarkAsResolvedFormCodec>;

export const TriageEmailDisassociateFromCaseForm: TFormCodec<TRequiredCodec<{ email_id: TUuidCodec }>, TEmptyObjectCodec> = form(
    required({ email_id: uuid() }),
    EmptyObject,
);
export type TTriageEmailDisassociateFromCaseFormCodec = typeof TriageEmailDisassociateFromCaseForm;
export type TTriageEmailDisassociateFromCaseForm = TTypeOfCodec<TTriageEmailDisassociateFromCaseFormCodec>;

export const TriageBulkEmailMarkAsIrrelevantForm: TFormCodec<TRequiredCodec<{ ids: TArrayCodec<TUuidCodec> }>, TEmptyObjectCodec> = form(
    required({ids: array(uuid())}),
    EmptyObject,
);
export type TTriageBulkEmailMarkAsIrrelevantFormCodec = typeof TriageBulkEmailMarkAsIrrelevantForm;
export type TTriageBulkEmailMarkAsIrrelevantForm = TTypeOfCodec<TTriageBulkEmailMarkAsIrrelevantFormCodec>;

export const DetailedEmailForm: TFormCodec<
    TUuidObjectCodec,
    TRequiredCodec<{
        email: TDetailedLegalEmailForTriageCodec,
        mark_as_irrelevant_form: TTriageEmailMarkAsIrrelevantFormCodec,
        mark_as_resolved_form: TTriageEmailMarkAsResolvedFormCodec,
        disassociate_from_case_form: TTriageEmailDisassociateFromCaseFormCodec,
        assign_to_case_search_form: TTriageEmailAssignToCaseSearchFormCodec,
        assign_to_user_only_forms: TArrayCodec<TTriageEmailAssignToUserOnlyFormCodec>,
        reply_form: TEmailCompositionFormCodec,
        forward_form: TEmailCompositionFormCodec,
    }>
> = form(
    UuidObject,
    required({
        email: DetailedLegalEmailForTriage,
        mark_as_irrelevant_form: TriageEmailMarkAsIrrelevantForm,
        mark_as_resolved_form: TriageEmailMarkAsResolvedForm,
        disassociate_from_case_form: TriageEmailDisassociateFromCaseForm,
        assign_to_case_search_form: TriageEmailAssignToCaseSearchForm,
        assign_to_user_only_forms: array(TriageEmailAssignToUserOnlyForm),
        reply_form: EmailCompositionForm,
        forward_form: EmailCompositionForm,
    }),
);
export type TDetailedEmailFormCodec = typeof DetailedEmailForm;
export type TDetailedEmailForm = TTypeOfCodec<TDetailedEmailFormCodec>;

export const TriageSimpleEmailForm: TFormCodec<TSimpleLegalEmailForTriageCodec, TIntersectionCodec<[
    TRequiredCodec<{
        detailed_email_form: TDetailedEmailFormCodec,
    }>,
    TPartialCodec<{
        is_read: TBooleanCodec,
    }>,
]>> = form(
    SimpleLegalEmailForTriage,
    intersection([
        required({
            detailed_email_form: DetailedEmailForm,
        }),
        partial({
            is_read: boolean(),
        }),
    ])
);
export type TTriageSimpleEmailFormCodec = typeof TriageSimpleEmailForm;
export type TTriageSimpleEmailForm = TTypeOfCodec<TTriageSimpleEmailFormCodec>;

export const SimpleLegalEmailWithPersonsForTriageForm: TFormCodec<TSimpleLegalEmailWithPersonsForTriageCodec, TIntersectionCodec<[
    TRequiredCodec<{
        detailed_email_form: TDetailedEmailFormCodec,
    }>,
    TPartialCodec<{
        is_read: TBooleanCodec,
    }>,
]>> = form(
    SimpleLegalEmailWithPersonsForTriage,
    intersection([
        required({
            detailed_email_form: DetailedEmailForm,
        }),
        partial({
            is_read: boolean(),
        }),
    ])
);
export type TSimpleLegalEmailWithPersonsForTriageFormCodec = typeof SimpleLegalEmailWithPersonsForTriageForm;
export type TSimpleLegalEmailWithPersonsForTriageForm = TTypeOfCodec<TSimpleLegalEmailWithPersonsForTriageFormCodec>;


const TriageFormChildren: TRequiredCodec<{
    emails: TArrayCodec<TTriageSimpleEmailFormCodec>,
    counts: TPagination3Codec,
    bulk_mark_as_irrelevant_form: TTriageBulkEmailMarkAsIrrelevantFormCodec,
}> = required({
    emails: array(TriageSimpleEmailForm),
    counts: Pagination3,
    bulk_mark_as_irrelevant_form: TriageBulkEmailMarkAsIrrelevantForm,
});
export type TTriageFormChildrenCodec = typeof TriageFormChildren;
export type TTriageFormChildren = TTypeOfCodec<TTriageFormChildrenCodec>;

export const TriageForm: TFormCodec<
    TIntersectionCodec<[
        TPagination1Codec, 
        TPartialCodec<{
            case_id: TAnyStringCodec,
            search_term: TAnyStringCodec,
        }>,
    ]>, 
    TTriageFormChildrenCodec
> = form(
    intersection([
        Pagination1,
        partial({
            case_id: string(),
            search_term: string(),
        }),
    ]),
    TriageFormChildren,
);
export type TTriageFormCodec = typeof TriageForm;
export type TTriageForm = TTypeOfCodec<TTriageFormCodec>;

export const TriageActiveTypeVisible = union([
    literal("UNASSIGNED"),
    literal("EXPIRED"),
]);
export type TTriageActiveTypeVisibleCodec = typeof TriageActiveTypeVisible;
export type TTriageActiveTypeVisible = TTypeOfCodec<TTriageActiveTypeVisibleCodec>;

export const TriageAssigneeActiveTypeVisible = union([
    literal("UNRESOLVED"),
    literal("RESOLVED"),
    literal("CONFIDENTIAL"),
    literal("UNTRIAGED"),
]);
export type TTriageAssigneeActiveTypeVisibleCodec = typeof TriageAssigneeActiveTypeVisible;
export type TTriageAssigneeActiveTypeVisible = TTypeOfCodec<TTriageAssigneeActiveTypeVisibleCodec>;