import React, { useState } from "react";
import { TListingFull1Editable } from "../../../../domain/codecs/formEditable/ListingEditable";
import { TListingCRMReadOnlyData1 } from "../../../../domain/codecs/Listing";
import { TUserWithValidLegalNames } from "../../../../domain/codecs/User";
import { CRMCardOutsidePopupBasic } from "../CRMCardOutsidePopupBasic/CRMCardOutsidePopupBasic";
import { CRMLink } from "../CRMLink/CRMLink";
import { CRMParagraph } from "../Simple/CRMParagraph/CRMParagraph";
import { CRMSpacingColumn } from "../CRMSpacingColumn/CRMSpacingColumn";
import { CRMTextHighlight } from "../CRMTextHighlight/CRMTextHighlight";
import { WeightBold } from "../WeightBold/WeightBold";
import { pipe } from "fp-ts/lib/function";
import { array } from "fp-ts";
import * as TCurrency from "../../../../domain/models/TCurrency";

type TCRMListingProgressionKickOffLinksProps = {
    listingForm: TListingFull1Editable;
    listingReadOnly: TListingCRMReadOnlyData1;
};

export const CRMListingProgressionKickOffLinks = (props: TCRMListingProgressionKickOffLinksProps): JSX.Element => {
    const [buyerSolicitorPopupIsOpen, setBuyerSolicitorPopupIsOpen] = useState<boolean>(false);
    const [sellerSolicitorPopupIsOpen, setSellerSolicitorPopupIsOpen] = useState<boolean>(false);
    const [mortgageBrokerPopupIsOpen, setMortgageBrokerPopupIsOpen] = useState<boolean>(false);

    const getSellerSolicitorMailLink = (): string =>
        `mailto:${props.listingForm.edited.seller_conveyancer_email_address}?subject=${props.listingReadOnly.address_short_string}&body=Hi ${props.listingForm.edited.seller_conveyancer_name},%0D%0A%0D%0AWhilst marketing the property, we have completed some preparation work that I hope will help make the sale of ${props.listingReadOnly.address_short_string} far smoother.%0D%0A%0D%0AIncluded for you:%0D%0A%0D%0AIf there is anything else I can do to help, please let me know.`

    const joinUserNames = (users: Array<TUserWithValidLegalNames>): string =>
        pipe(
            users,
            array.map((user) => [
                ...user.homes_first_legal_name ? [user.homes_first_legal_name] : [],
                ...user.homes_middle_legal_name ? [user.homes_middle_legal_name] : [],
                ...user.homes_last_legal_name ? [user.homes_last_legal_name] : [],
            ].join(" ")),
            (a) => a.join(", "),
        );

    const getBuyerName = (): string =>
        props.listingReadOnly.purchasers_company_name.trim()
            ? `${props.listingReadOnly.purchasers_company_name} (${joinUserNames(props.listingReadOnly.buyer_users)})`
            : joinUserNames(props.listingReadOnly.buyer_users);

    return (
        <>
            {props.listingReadOnly.listing_has_offer_accepted === true && <CRMSpacingColumn spacing="tiny">
                <CRMLink
                    onClick={() => setBuyerSolicitorPopupIsOpen(true)}
                    href=""
                    linkStyle="normal"
                >
                    <WeightBold>Open buyer solicitor email template</WeightBold>
                </CRMLink>
                <CRMLink
                    onClick={() => setSellerSolicitorPopupIsOpen(true)}
                    href=""
                    linkStyle="normal"
                >
                    <WeightBold>Open seller solicitor email template</WeightBold>
                </CRMLink>
                {props.listingReadOnly.buyer_payment_method === "mortgage" && props.listingReadOnly.mortgage_broker_email_address && <CRMLink
                    onClick={() => setMortgageBrokerPopupIsOpen(true)}
                    href=""
                    linkStyle="normal"
                >
                    <WeightBold>Open mortgage broker email template</WeightBold>
                </CRMLink>}
                <CRMLink href={`${env.REACT_APP_API_URL}/web/listings/${props.listingForm.edited.id}/mos`} target="_blank" linkStyle="normal">
                    Memorandum of Sale
                </CRMLink>
                <CRMLink href={`${env.REACT_APP_API_URL}/web/listings/${props.listingForm.edited.id}/best-price-certificate`} target="_blank" linkStyle="normal">
                    Aventria best price certificate
                </CRMLink>
            </CRMSpacingColumn>}

            {/* Buyer solicitor popup */}
            <CRMCardOutsidePopupBasic
                isOpen={buyerSolicitorPopupIsOpen}
                title="Take care before sending"
                context="warning"
                isInfo={true}
                onClose={() => setBuyerSolicitorPopupIsOpen(false)}
                closeText="Cancel"
            >
                <CRMSpacingColumn spacing="medium">
                    <CRMParagraph>
                        <CRMTextHighlight colour="highlights-instructional-brick-8">
                            Before sending the email check you are sending it from your Sail Homes address.
                        </CRMTextHighlight>
                    </CRMParagraph>
                    <CRMLink
                        target="_blank"
                        linkStyle="normal"
                        href={`mailto:${props.listingReadOnly.buyer_conveyancer_email_address}
                        ?subject=${props.listingReadOnly.address_short_string}
                        &body=For the attention of: ${props.listingReadOnly.buyer_conveyancer_name},%0D%0A%0D%0APlease find attached the Memorandum of Sale for the purchase of:%0D%0A${props.listingReadOnly.address_short_string}%0D%0A%0D%0A${props.listingForm.edited.seller_conveyancer_organisation_name} will send you draft contracts and official copies, as well as these documents too, but I wanted to share them with you directly so you can get the ball rolling.%0D%0A%0D%0AIncluded for you:%0D%0A%0D%0ACan you please send me a quick response to confirm that you have received these?%0D%0A%0D%0AAny questions, let me know.`}
                    >
                        <WeightBold>Open email template</WeightBold>
                    </CRMLink>
                </CRMSpacingColumn>
            </CRMCardOutsidePopupBasic>

            {/* Seller solicitor popup */}
            <CRMCardOutsidePopupBasic
                isOpen={sellerSolicitorPopupIsOpen}
                title="Take care before sending"
                context="warning"
                isInfo={true}
                onClose={() => setSellerSolicitorPopupIsOpen(false)}
                closeText="Cancel"
            >
                <CRMSpacingColumn spacing="medium">
                    <CRMParagraph>
                        <CRMTextHighlight colour="highlights-instructional-brick-8">
                            Before sending the email check you are sending it from your Sail Homes address.
                        </CRMTextHighlight>
                    </CRMParagraph>
                    <CRMLink
                        target="_blank"
                        href={getSellerSolicitorMailLink()}
                        linkStyle="normal"
                    >
                        <WeightBold>Open email template</WeightBold>
                    </CRMLink>
                </CRMSpacingColumn>
            </CRMCardOutsidePopupBasic>

            {/* Mortgage broker popup */}
            <CRMCardOutsidePopupBasic
                isOpen={mortgageBrokerPopupIsOpen}
                title="Take care before sending"
                context="warning"
                isInfo={true}
                onClose={() => setMortgageBrokerPopupIsOpen(false)}
                closeText="Cancel"
            >
                <CRMSpacingColumn spacing="medium">
                    <CRMParagraph>
                        <CRMTextHighlight colour="highlights-instructional-brick-8">
                            Before sending the email check you are sending it from your Sail Homes address.
                        </CRMTextHighlight>
                    </CRMParagraph>
                    <CRMLink
                        target="_blank"
                        linkStyle="normal"
                        href={`mailto:${props.listingReadOnly.mortgage_broker_email_address}
                        ?subject=Mortgage application can now be submitted - ${getBuyerName()}
                        &body=Hi ${props.listingReadOnly.mortgage_broker_name},%0D%0A%0D%0AThis is just a quick email to let you know that your client's offer of ${TCurrency.fromNumber(props.listingReadOnly.accepted_offer_amount)} has been accepted:%0D%0A%0D%0AClient:%0D%0A${getBuyerName()}%0D%0A%0D%0AProperty:%0D%0A${props.listingReadOnly.address_short_string}%0D%0A%0D%0AA mortgage application can now be submitted if your client is happy to proceed.%0D%0A%0D%0APlease let me know if you have any questions.`}
                    >
                        <WeightBold>Open email template</WeightBold>
                    </CRMLink>
                </CRMSpacingColumn>
            </CRMCardOutsidePopupBasic>
        </>
    );
};
