import React from "react";
import { TCaseSearchResult } from "../../../../../domain/codecs/CaseSearchResult";
import { Address1ToCopyText } from "../../../../../domain/codecs/util/Address1ToCopyText";
import { CRMFontSizes } from "../../../models/CRMFontSizes";
import { CRMSpacing } from "../../../models/CRMSpacing";
import { Padding } from "../../BuildingBlocks/Padding";
import { SpacingColumn } from "../../BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../BuildingBlocks/SpacingRow";
import { CRMCardOutside } from "../../CRMCardOutside/CRMCardOutside";
import { CRMParagraph } from "../../Simple/CRMParagraph/CRMParagraph";
import { CRMIcon } from "../../CRMIcon/CRMIcon";
import { WeightBold } from "../../WeightBold/WeightBold";
import { FontSignikaNegative } from "../../BuildingBlocks/FontSignikaNegative";
import { Uppercase } from "../../Uppercase/Uppercase";
import { FontSize } from "../../BuildingBlocks/FontSize";
import { CRMPill } from "../../Simple/CRMPill/CRMPill";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { TCaseStatus } from "../../../../../domain/codecs/Cases";
import { requireExhaustive } from "../../../../../shared/src/util";

type TCRMCaseCardProps = {
    case: TCaseSearchResult;
    buttons: Array<TCaseCardLinkButton>;
};

export const CRMSearchCaseCard = (props: React.PropsWithChildren<TCRMCaseCardProps>): JSX.Element => {

    const getClientLegalName = (client: TCaseSearchResult["client_users"][number]): string => {
        if (doesClientHaveALegalName(client)) {
            return `(${client.legal_first_legal_name} ${client.legal_middle_legal_name} ${client.legal_last_legal_name})`
                .replace(/\s\s/, " ");
        }
        return "";
    }

    const doesClientHaveALegalName = (client: TCaseSearchResult["client_users"][number]): boolean =>
        !!client.legal_first_legal_name &&
        !!client.legal_last_legal_name
    ;

    const isDualRep = props.case.is_dual_rep;

    const getCaseStatusPillText = (status: TCaseStatus) => {
        switch (status) {
            case "instructed":
            case "ready_for_exchange":
            case "exchanged":
                return "Open";
            
            case "quote":
                return "Quote";
            
            case "property_report":
                return "Prop Report";
            
            case "completed":
                return "Completed";

            case "closed_awaiting_opening_new_case":
                return "Closed - Awaiting New Case"
            
            case "closed_successfully":
            case "closed_unsuccessfully":
            case "closed_created_in_error":
                return "Closed";
            default:
                return requireExhaustive(status);
        }
    }

    return (
        <div className={`crm-search-case-card`}>
            <CRMCardOutside borderRounding="right">
                <Padding 
                    type="custom" 
                    spacing={`${CRMSpacing.LARGE} ${CRMSpacing.LARGE} ${CRMSpacing.MEDIUM} ${CRMSpacing.LARGE}`}
                >
                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                        <SpacingRow
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={CRMSpacing.MEDIUM}
                        >
                            {/* TITLE AS MATCHING ADDRESSES */}
                            <SpacingColumn spacing={CRMSpacing.TINY}>
                                {props.case.addresses.map((address, key) => (
                                    <CRMParagraph key={key} fontSize={CRMFontSizes.SMALL}>
                                        <WeightBold>
                                            {Address1ToCopyText(address)}
                                        </WeightBold>
                                    </CRMParagraph>
                                ))}
                            </SpacingColumn>

                            <SpacingRow
                                spacing={CRMSpacing.TINY}
                            >
                                {isDualRep &&
                                    <CRMPill colour="highlights-calming-pink-10">
                                        <WeightSemiBold>
                                            Dual Rep
                                        </WeightSemiBold>
                                    </CRMPill>
                                }
                                
                                <CRMPill colour={getCaseStatusPillText(props.case.status) === "Open" ? "highlights-positive-green-10"
                                    : getCaseStatusPillText(props.case.status) === "Closed" ? "neutral-10"
                                    : "highlights-pertinent-yellow-10"
                                }>
                                <div style={{whiteSpace: "nowrap"}}>
                                    <WeightSemiBold>
                                        {getCaseStatusPillText(props.case.status)}
                                    </WeightSemiBold>
                                </div>
                                </CRMPill>
                                
                            </SpacingRow>
                            
                        </SpacingRow>
                        
                        {/* CLIENT NAMES */}
                        <SpacingColumn spacing={CRMSpacing.TINY}>
                            {props.case.client_users.map((client, key) => (
                                <SpacingRow spacing={CRMSpacing.TINY}>
                                    <CRMIcon
                                        iconName="person"
                                        colour="neutral-ink"
                                        size="tiny"
                                    />
                                    <CRMParagraph key={key} fontSize={CRMFontSizes.SMALL}>
                                        {`${client.first_name} ${client.last_name}`} {getClientLegalName(client)}
                                    </CRMParagraph>
                                </SpacingRow>
                            ))}
                        </SpacingColumn>
                        
                        {/* KLYANT ID */}
                        {props.case.klyant_matter_id.trim() !== "" &&
                            <SpacingRow spacing={CRMSpacing.TINY}>
                                <CRMParagraph fontSize={CRMFontSizes.SMALL}>
                                    <WeightBold>Klyant Matter Id: </WeightBold>{props.case.klyant_matter_id}
                                </CRMParagraph>
                            </SpacingRow>
                        }

                        
                        {/* LINK BUTTONS */}
                        <SpacingRow>
                            {props.buttons.map((button, index) => (
                                <LinkButton
                                    key={index}
                                    {...button}
                                />
                            ))}
                        </SpacingRow>
                    </SpacingColumn>


                </Padding>
            </CRMCardOutside>
        </div>
    );
};

type TCaseCardLinkButton = {
    label: string;
    link: string;
}

const LinkButton = (props: React.PropsWithChildren<TCaseCardLinkButton>): JSX.Element => {
    return (
        <a 
            href={props.link}
            className="crm-search-case-card__link-button"
        >
            <SpacingRow spacing={CRMSpacing.TINY}>
                <FontSize size={CRMFontSizes.SMALL}>
                    <FontSignikaNegative>
                        <Uppercase>
                            {props.label}
                        </Uppercase>
                    </FontSignikaNegative>
                </FontSize>

                <CRMIcon
                    iconName="open"
                    colour="neutral-ink"
                    size="x-small"
                />
            </SpacingRow>
        </a>
    );
}