import React from "react";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { CRMEntityBoxButton } from "../../Simple/CRMEntityBoxButton/CRMEntityBoxButton";
import { CRMTitleSubSection } from "../../../CRMTitleSubSection/CRMTitleSubSection";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { CRMCaseContractTransferRecord, TRecordEntityOption, TRecordEntityType } from "../CRMCaseContractTransferRecord/CRMCaseContractTransferRecord";
import { InlineFlex } from "../../../BuildingBlocks/InlineFlex";
import { TTransferSetupRecordForm } from "../../../../../../domain/codecs/form/TransferSetupRecordForm";
import { TTransferCompanyType, TTransferRecordType } from "../../../../../../domain/codecs/CaseTransferSetup";
import { TCaseContractSectionForm } from "../../../../../../domain/codecs/form/CaseContractSectionForm";
import { NewTransferSetupRecordForCaseForm, TNewTransferSetupRecordForCaseForm } from "../../../../../../domain/codecs/form/NewTransferSetupRecordForCaseForm";
import { cleanName } from "../../../../../../shared/src/utilsByDomain/name/cleanName";
import { pipe } from "fp-ts/lib/pipeable";
import { TCaseOtherSideMemberForm } from "../../../../../../domain/codecs/form/CaseOtherSideMemberForm";
import { TUsersCasesUserLegalName } from "../../../../../../domain/codecs/UsersCasesUserLegalName";
import { ifTrueInject } from "../../../../../../shared/src/utilsByDomain/array";
import { array } from "fp-ts";
import { underscoreCaseToNormalCase } from "../../../../../../shared/src/utilsByDomain/string";


type TTransferRecords = Array<TTransferSetupRecordForm>;

type TCRMCaseContractTransferSetupRecordsProps = {
    form: TCaseContractSectionForm;
    onAddRecord: (type: TNewTransferSetupRecordForCaseForm) => void;
    onChangeRecord: (form: TTransferSetupRecordForm) => void;
    onDeleteRecord: (form: TTransferSetupRecordForm) => void;
};

export const CRMCaseContractTransferSetupRecords = (props: React.PropsWithChildren<TCRMCaseContractTransferSetupRecordsProps>): JSX.Element => {
    
    const EntityOptions: Array<TRecordEntityOption> = pipe(
        [
            props.form.children.other_side_members.map(otherClientToEntityOption),
            props.form.children.our_side_case_users.map(ourClientToEntityOption),
        ],
        injectValidCompanyAsEntityOption("our_side", props.form.children.our_side_company_name),
        injectValidCompanyAsEntityOption("other_side", props.form.children.details.edited.other_side_company_name),
        array.flatten
    )
    
    const isRecordOfType = (type: TTransferRecordType) => 
        (record: TTransferSetupRecordForm) => record.edited.record_type === type
    ;

    const IsUsingAS1 = !!props.form.children.details.edited.using_as1;

    const AllRecords = props.form.children.transfer_setup_records;
    const TransferorRecords = AllRecords.filter(isRecordOfType("transferor"));
    const TransfereeRecords = AllRecords.filter(isRecordOfType("transferee"));
    const DeceasedRecords = AllRecords.filter(isRecordOfType("last_deceased_for_as1"));
    const SecondTrusteeRecords = AllRecords.filter(isRecordOfType("second_trustee"));

    const onAddRecord = (type: TTransferRecordType) => 
        () => props.onAddRecord({
            ...NewTransferSetupRecordForCaseForm.newDefault(),
            edited: {
                case_id: props.form.edited.case_id,
                record_type: type
            }
        });

    return (
        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
            
            <Section
                title={`Transferor`}
                verbWord="transferor"
                records={TransferorRecords}
                onAddRecord={onAddRecord("transferor")}
                onChangeRecord={props.onChangeRecord}
                onDeleteRecord={props.onDeleteRecord}
                entityOptions={EntityOptions}
            />
            
            <Section
                title={`Transferee`}
                verbWord="transferee"
                records={TransfereeRecords}
                onAddRecord={onAddRecord("transferee")}
                onChangeRecord={props.onChangeRecord}
                onDeleteRecord={props.onDeleteRecord}
                entityOptions={EntityOptions}
            />
            
            {/* Deceased (AS1) (only if using AS1 (timestamp in details) ) */}
            {IsUsingAS1 &&
                <Section
                    title={`Deceased (AS1)`}
                    verbWord="the deceased"
                    records={DeceasedRecords}
                    onAddRecord={onAddRecord("last_deceased_for_as1")}
                    onChangeRecord={props.onChangeRecord}
                    onDeleteRecord={props.onDeleteRecord}
                    entityOptions={EntityOptions}
                />
            }
            
            <Section
                title={`Second Trustee`}
                verbWord="second trustee"
                records={SecondTrusteeRecords}
                onAddRecord={onAddRecord("second_trustee")}
                onChangeRecord={props.onChangeRecord}
                onDeleteRecord={props.onDeleteRecord}
                entityOptions={EntityOptions}
            />

        </SpacingColumn>
    );
};


type TSectionProps = {
    title: string;
    verbWord: string;
    records: TTransferRecords;
    onAddRecord: () => void;
    onChangeRecord: (form: TTransferSetupRecordForm) => void;
    onDeleteRecord: (form: TTransferSetupRecordForm) => void;
    entityOptions: Array<TRecordEntityOption>;
}

const Section = (props: TSectionProps) => {
    return (
        <SpacingColumn spacing={CRMSpacing.MEDIUM}>
            {/* TITLE */}
            <CRMTitleSubSection>
                {props.title}
            </CRMTitleSubSection>

            {/* BUTTON - ADD */}
            <CRMEntityBoxButton
                icon="person-add"
                label={`Add person or company`}
                onClick={props.onAddRecord}
            />

            {/* RECORDS */}
            <SpacingColumn 
                spacing={CRMSpacing.SMALL}
                maxWidth={"420px"}
            >
                {props.records.map((record, index) => (
                    <CRMCaseContractTransferRecord
                        key={`${record.edited.id}-${index}`}
                        form={record}
                        dropdownPlaceholder={`Select ${props.verbWord}`}
                        entityOptions={props.entityOptions}
                        onChange={props.onChangeRecord}
                        onDelete={props.onDeleteRecord}
                    />
                ))}
            </SpacingColumn>
        </SpacingColumn>
    )
}


const otherClientToEntityOption = (client: TCaseOtherSideMemberForm ): TRecordEntityOption => ({
    value: client.original.users__cases___other_side_id,
    label: cleanName(
        client.edited.legal_first_legal_name,
        client.edited.legal_middle_legal_name,
        client.edited.legal_last_legal_name,
    ) || "Unamed Client (other side)",
    meta: { 
        type: "other-side-client"
    }
});

const ourClientToEntityOption = (client: TUsersCasesUserLegalName ): TRecordEntityOption => ({
    value: client.users__cases_id,
    label: cleanName(
        client.legal_first_legal_name,
        client.legal_middle_legal_name,
        client.legal_last_legal_name,
    ) || "Unamed Client (our side)",
    meta: { 
        type: "our-side-client"
    }
});

const injectValidCompanyAsEntityOption = (type: TTransferCompanyType, name: string | null) =>
    ifTrueInject<Array<TRecordEntityOption>>(
        () => name !== null,
        [{
            value: type,
            label: name || `Unamed company (${underscoreCaseToNormalCase(type)})`,
            meta: {
                type: "company"
            }
        }]
    )
;