import React from "react";
import { TCaseEnquiry1, CaseEnquiry1Sort } from "../../../../../../domain/codecs/CaseEnquiry";
import { TCasesDocumentFile1 } from "../../../../../../domain/codecs/CasesDocument";
import { EmailTemplateBody } from "../../../BuildingBlocks/EmailTemplateBody";
import { EmailTemplateColumn } from "../../../BuildingBlocks/EmailTemplateColumn";
import { EmailTemplateRow } from "../../../BuildingBlocks/EmailTemplateRow";
import { EmailTemplateBlock } from "../../../BuildingBlocks/EmailTemplateBlock";
import { FontArial } from "../../../BuildingBlocks/FontArial";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { FontSize } from "../../../BuildingBlocks/FontSize";
import { isFilled } from "../../../../../../shared/src/util";
import { Border } from "../../../BuildingBlocks/Border";
import { FrontColors } from "../../../../models/FrontColors";
import { FrontFontSizes } from "../../../../models/FrontFontSizes";
import { MinWidth } from "../../../BuildingBlocks/MinWidth";
import { Underline } from "../../../Underline/Underline";
import { CursorPointer } from "../../../CursorPointer/CursorPointer";
import { TextColor } from "../../../BuildingBlocks/TextColor";
import { TStaffUser } from "../../../../../../domain/codecs/User";
import { TCaseChargeLegalFile2 } from "../../../../../../domain/codecs/CaseChargeLegalFile";
import { TQuoteEmailAttachment } from "../../../../../../domain/codecs/Quote";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { WeightMedium } from "../../../WeightMedium/WeightMedium";
import { WeightSemiBold } from "../../../WeightSemiBold/WeightSemiBold";

type TCRMLegalEmail = {
    subject: string;
    body: string;
    enquiries: Array<TCaseEnquiry1>; 
    files: Array<TCasesDocumentFile1>;
    redemptionStatements: Array<TCaseChargeLegalFile2>;
    quotes: Array<TQuoteEmailAttachment>;
    senderDetails: TStaffUser;
    apiUrl: string;
};

export const CRMLegalEmail = (props: React.PropsWithChildren<TCRMLegalEmail>): JSX.Element => {
    const fileCount: number =
        props.files.length
        + props.redemptionStatements.length
        + props.quotes.length;

    return (
        <>
            <EmailTemplateBody>
                {/* SUBJECT TITLE */}
                <EmailTemplateBlock padding={[20, 0, 0, 0]}>
                    <LegalEmailTitle>
                        {props.subject}
                    </LegalEmailTitle>
                </EmailTemplateBlock>
                
                {/* BODY */}
                <EmailTemplateBlock padding={[20, 0, 0, 0]}>
                    <LegalEmailParagraph>
                        <div dangerouslySetInnerHTML={{ __html: props.body }} />
                    </LegalEmailParagraph>
                </EmailTemplateBlock>
                
                {/* SIGNATURE */}
                <EmailTemplateBlock padding={[20, 0, 0, 0]}>
                    <LegalEmailParagraph>
                        {props.senderDetails.first_name} {props.senderDetails.last_name}
                    </LegalEmailParagraph>
                    <LegalEmailParagraph>
                        Sail Legal
                    </LegalEmailParagraph>
                    <LegalEmailParagraph>
                        0808 196 7007
                    </LegalEmailParagraph>
                    <LegalEmailParagraph>
                        www.saillegal.co.uk
                    </LegalEmailParagraph>
                </EmailTemplateBlock>


                {/* SECTION: ATTACHED ENQUIRIES */}
                {isFilled(props.enquiries) &&
                    <EmailTemplateBlock padding={[40, 0, 0, 0]}>
                        {/* TITLE */}
                        <LegalEmailSectionTitle>
                            Enquiries ({props.enquiries.length})
                        </LegalEmailSectionTitle>

                        {/* ENQUIRIES */}
                        <EmailTemplateBlock padding={[20, 0, 0, 0]}>
                            {props.enquiries.sort(CaseEnquiry1Sort).map((enquiry, index) => (
                                <EmailTemplateBlock
                                    key={enquiry.id}
                                    padding={[0, 0, 20, 0]}
                                >
                                    <LegalEmailEnquiry
                                        index={index+1}
                                        enquiry={enquiry}
                                    />
                                </EmailTemplateBlock>
                            ))}
                        </EmailTemplateBlock>
                    </EmailTemplateBlock>
                }

                {/* SECTION: ATTACHED FILES, REDEMPTION STATEMENTS & QUOTES */}
                {(isFilled(props.files) || isFilled(props.redemptionStatements) || isFilled(props.quotes)) &&
                    <EmailTemplateBlock padding={[40, 0, 0, 0]}>
                        {/* TITLE */}
                        <LegalEmailSectionTitle>
                            Files ({fileCount})
                        </LegalEmailSectionTitle>

                        {/* INSTRUCTION TEXT */}
                        <EmailTemplateBlock padding={[20, 0, 0, 0]}>
                            <LegalEmailParagraph>
                                Please click on the file names below in order to download them. The download will happen in a new tab.
                            </LegalEmailParagraph>
                        </EmailTemplateBlock>

                        <EmailTemplateBlock padding={[20, 0, 0, 0]}>
                            {/* FILES */}
                            {props.files.map((file) => (
                                <EmailTemplateBlock
                                    key={file.id}
                                    padding={[0, 0, 15, 0]}
                                >
                                    <LegalEmailFile 
                                        file={file}
                                        apiUrl={props.apiUrl}
                                    />
                                </EmailTemplateBlock>
                            ))}

                            {/* REDEMPTION STATEMENTS */}
                            {props.redemptionStatements.map((redemptionStatement) => (
                                <EmailTemplateBlock
                                    key={redemptionStatement.id}
                                    padding={[0, 0, 15, 0]}
                                >
                                    <LegalEmailRedemptionStatement 
                                        redemptionStatement={redemptionStatement}
                                        apiUrl={props.apiUrl}
                                    />
                                </EmailTemplateBlock>
                            ))}

                            {/* QUOTES */}
                            {props.quotes.map((quote) => (
                                <EmailTemplateBlock
                                    key={quote.id}
                                    padding={[0, 0, 15, 0]}
                                >
                                    <LegalEmailQuote 
                                        quote={quote}
                                    />
                                </EmailTemplateBlock>
                            ))}
                        </EmailTemplateBlock>
                    </EmailTemplateBlock>
                }

                {/* POST */}
                <EmailTemplateBlock padding={[10, 0, 20, 0]}>
                    <EmailTemplateBlock padding={[30, 0, 0, 0]}>
                        <EmailTemplateBlock padding={[0, 0, 10, 0]}>
                            <LegalEmailSectionTitle>
                                Need to post something to us?&nbsp;
                            </LegalEmailSectionTitle>
                        </EmailTemplateBlock>
                            
                        <LegalEmailParagraph>
                            <WeightMedium>
                                Our address is&nbsp;
                            </WeightMedium>
                            <WeightSemiBold>
                                Sail Legal, Henleaze House, 13 Harbury Rd, Henleaze, BS9 4PN.
                            </WeightSemiBold>
                        </LegalEmailParagraph>
                    </EmailTemplateBlock>
                </EmailTemplateBlock>

                {/* FOOTER/LEGAL DISCLAIMER */}
                <EmailTemplateBlock padding={[10, 0, 20, 0]}>
                    <Border
                        type="top"
                        definition={`1px solid ${FrontColors.NEUTRAL_10_2}`}
                    >   
                        <EmailTemplateBlock padding={[30, 0, 0, 0]}>
                            <LegalEmailFinePrint>
                                Sail Legal Limited is regulated and licensed by the Council for Licensed Conveyancers as an Alternative Business Structure (ABS) number 14065 under the provisions of the Legal Services Act 2007. 
                                Sail Legal Limited registered in England & Wales bearing Company No 13307984. Address and Registered office: 217 Regus House Chester Business Park, Herons Way, Chester, England, CH4 9QR. 
                                A list of the Directors is available upon request or for inspection at our Registered Office. VAT Registration Number: 3883174763.
                            </LegalEmailFinePrint>
                            
                            <LegalEmailFinePrint>
                                This communication and the information it contains are intended for the person or organisation to which it is addressed. Its contents are confidential and may be protected in law. 
                                Unauthorised use, copying or disclosure of any of it may be unlawful. If you are not the intended recipient, please contact us immediately.
                            </LegalEmailFinePrint>

                            <LegalEmailFinePrint>
                                Please be aware that there is a significant risk posed by cyber fraud, specifically affecting email accounts and bank account details. 
                                PLEASE NOTE that the Sail Legal Limited bank account details WILL NOT change during the course of a transaction and Sail Legal will NOT change its bank account details via email. 
                                Please be careful to check account details with us in person if in any doubt. Sail Legal will not accept responsibility if you transfer money into an incorrect account.
                            </LegalEmailFinePrint>
                        </EmailTemplateBlock>
                    </Border>
                </EmailTemplateBlock>
            </EmailTemplateBody>
        </>
    );
};

type TLegalEmailFile = {
    file: TCasesDocumentFile1;
    apiUrl: string;
};

const LegalEmailFile = (props: React.PropsWithChildren<TLegalEmailFile>): JSX.Element => {
    return (
        <EmailTemplateBlock>
            <LegalEmailLink
                link={`${props.apiUrl}/web/case-document-file-download?fileToken=${window.encodeURIComponent(props.file.download_token)}`}
            >
                {props.file.name}.{props.file.file_extension}
            </LegalEmailLink>
        </EmailTemplateBlock>
    );
}

const LegalEmailRedemptionStatement = (props: React.PropsWithChildren<{
    redemptionStatement: TCaseChargeLegalFile2,
    apiUrl: string,
}>): JSX.Element => {
    return (
        <EmailTemplateBlock>
            <LegalEmailLink
                link={`${props.apiUrl}/web/legal-file-download?fileToken=${window.encodeURIComponent(props.redemptionStatement.download_token)}&fileName=${window.encodeURIComponent(`${props.redemptionStatement.beneficiary} redemption statement`)}`}
            >
                {props.redemptionStatement.beneficiary} Redemption statement
            </LegalEmailLink>
        </EmailTemplateBlock>
    );
}

const LegalEmailQuote = (props: React.PropsWithChildren<{
    quote: TQuoteEmailAttachment,
}>): JSX.Element => {
    return (
        <EmailTemplateBlock>
            <LegalEmailLink
                link={props.quote.download_url}
            >
                Quote
            </LegalEmailLink>
        </EmailTemplateBlock>
    );
}

type TLegalEmailEnquiry = {
    index: number;
    enquiry: TCaseEnquiry1;
};

const LegalEmailEnquiry = (props: React.PropsWithChildren<TLegalEmailEnquiry>): JSX.Element => {
    return (
        <EmailTemplateBlock>
            <EmailTemplateRow>
                {/* ENQUIRY REFERENCE NUMBER */}
                <EmailTemplateColumn>
                    <MinWidth width="50px">
                        <LegalEmailParagraph>
                            <WeightBold>
                                {props.enquiry.reference_id || props.index}.
                            </WeightBold>
                        </LegalEmailParagraph>
                    </MinWidth>
                </EmailTemplateColumn>

                {/* MESSAGE */}
                <EmailTemplateColumn>
                    <MinWidth width="550px">
                        <LegalEmailParagraph>
                            {props.enquiry.message}
                        </LegalEmailParagraph>
                    </MinWidth>
                </EmailTemplateColumn>
            </EmailTemplateRow>
        </EmailTemplateBlock>
    );
}


type TLegalEmailLink = {
    link: string;
}

const LegalEmailLink = (props: React.PropsWithChildren<TLegalEmailLink>): JSX.Element => {
    return (
        <EmailTemplateBlock>
            <CursorPointer>
                <FontArial>
                    <FontSize
                        size={FrontFontSizes.MED} 
                        lineHeight={1.3}
                    >
                        <Underline>
                            <a 
                                href={props.link} 
                                target="_blank"
                                style={{
                                    color: FrontColors.PRIMARY_2
                                }}
                            >
                                {props.children}
                            </a>
                        </Underline>
                    </FontSize>
                </FontArial>
            </CursorPointer>
        </EmailTemplateBlock>
    );
}

const LegalEmailParagraph = (props: React.PropsWithChildren<{}>): JSX.Element => {
    return (
        <EmailTemplateBlock>
            <FontArial>
                <FontSize
                    size={FrontFontSizes.MED} 
                    lineHeight={1.3}
                >
                    {props.children}
                </FontSize>
            </FontArial>
        </EmailTemplateBlock>
    );
}

const LegalEmailFinePrint = (props: React.PropsWithChildren<{}>): JSX.Element => {
    return (
        <EmailTemplateBlock align="center">
            <FontArial>
                <FontSize
                    size={FrontFontSizes.X_SMALL} 
                    lineHeight={1.4}
                >
                    <TextColor color={FrontColors.NEUTRAL_6}>
                        {props.children}
                    </TextColor>
                </FontSize>
            </FontArial>
        </EmailTemplateBlock>
    );
}

const LegalEmailTitle = (props: React.PropsWithChildren<{}>): JSX.Element => {
    return (
        <FontArial>
            <FontSize
                size={FrontFontSizes.X_LARGE}
                lineHeight={1.4}
            >
                <WeightBold>
                    {props.children}
                </WeightBold>
            </FontSize>
        </FontArial>
    );
}

const LegalEmailSectionTitle = (props: React.PropsWithChildren<{}>): JSX.Element => {
    return (
        <EmailTemplateBlock>
            <Border
                type="bottom"
                definition={`3px solid ${FrontColors.NEUTRAL_10_2}`}
            >
                <EmailTemplateBlock padding={[0, 0, 10, 0]}>
                    <FontArial>
                        <FontSize
                            size={FrontFontSizes.LARGE}
                            lineHeight={1.2}
                        >
                            <WeightBold>
                                {props.children}
                            </WeightBold>
                        </FontSize>
                    </FontArial>
                </EmailTemplateBlock>
            </Border>
        </EmailTemplateBlock>
    );
}