import React from "react";
import { C as State } from "./../../state/State";
import { TDispatch } from "./../../state/TDispatch";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { CRMListingAddressForm } from "./../../components/CRMListingAddressForm/CRMListingAddressForm";
import { CRMSpacingColumn } from "./../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMCardOutside } from "./../../components/CRMCardOutside/CRMCardOutside";
import { CRMListingSpecificsForm } from "./../../components/CRMListingSpecificsForm/CRMListingSpecificsForm";
import { CRMListingPriceAndCostsForm } from "./../../components/CRMListingPriceAndCostsForm/CRMListingPriceAndCostsForm";
import { CRMListingSellingProcessForm } from "./../../components/CRMListingSellingProcessForm/CRMListingSellingProcessForm";
import { CRMListingCompetingAgentForm } from "./../../components/CRMListingCompetingAgentForm/CRMListingCompetingAgentForm";
import { CRMListingConveyancingForm } from "./../../components/CRMListingConveyancingForm/CRMListingConveyancingForm";
import { CRMListingFormSections, TCRMListingFormSections } from "../../models/CRMFormSections";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMTitleSubSection } from "../../components/CRMTitleSubSection/CRMTitleSubSection";
import { CRMSailHomesAdHocTasksCard } from "../../components/CRMSailHomesAdHocTasksCard/CRMSailHomesAdHocTasksCard";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMListingFileUpload } from "../../components/CRMListingFileUpload/CRMListingFileUpload";
import CRMListingKeyManagementForm from "../CRMListingKeyManagementForm/CRMListingKeyManagementForm";
import { CRMListingDocumentManagementsForm } from "../../components/CRMListingDocumentManagementsForm/CRMListingDocumentManagementsForm";
import { CRMListingMarketingFormContainer } from "../CRMListingMarketingFormContainer/CRMListingMarketingFormContainer";
import { CRMListingSignsContainer } from "../CRMListingSignsContainer/CRMListingSignsContainer";
import { CRMLink } from "../../components/CRMLink/CRMLink";
import { WeightBold } from "../../components/WeightBold/WeightBold";
import { Routes } from "../../state/router/routerRoutes";

type TListingPagePrepContainerProps = {
    state: State<Routes>;
    dispatch: TDispatch;
};

export const CRMListingPagePrepContainer = (props: TListingPagePrepContainerProps): JSX.Element => {

    const isDetailsSectionSelected = (section: TCRMListingFormSections[number]): boolean => 
        CRMListingFormSections.isDetailsSectionSelected(
            props.state.routes.queryParams.VIEW_CRM_LISTING.visible_sections,
            section,
        );

    return (
        <SpacingRow spacing={CRMSpacing.X_LARGE} childSize="1fr 2fr">
            {/* COLUMN - FORM */}
            <SpacingColumn spacing={CRMSpacing.LARGE}>
                <CRMSailHomesAdHocTasksCard
                    addNewTaskFormStatus={props.state.forms.listing_page.create_ad_hoc_task.status}
                    tasks={props.state.forms.listing_page.data.output.ad_hoc_tasks.filter((task) => task.original.section === "prep")}
                    onUpdateTasks={(payload) => props.dispatch({
                        type: "LISTING_PAGE_UPDATE_AD_HOC_TASKS",
                        payload,
                    })}
                    onAddNewTask={(task) => props.dispatch({
                        type: "LISTING_PAGE_ADD_AD_HOC_TASK",
                        payload: {
                            task,
                            listing_id: props.state.forms.listing_page.data.output.form.original.id,
                            section: "prep"
                        }
                    })}
                />

                <CRMCardOutside
                    borderRounding="right"
                    shadow={true}
                >
                    <CRMPadding size="large">
                        <CRMSpacingColumn spacing="large">
                            {isDetailsSectionSelected("address") && 
                                <CRMListingAddressForm
                                    listingForm={props.state.forms.listing_page.data.output.form}
                                    onChange={(form) => props.dispatch({
                                        type: "LISTING_PREPARATION_FORM_CHANGE",
                                        payload: form
                                    })}
                                />
                            }
                            {isDetailsSectionSelected("price_and_costs") && 
                                <CRMListingPriceAndCostsForm
                                    listingForm={props.state.forms.listing_page.data.output.form}
                                    onChange={(form) => props.dispatch({
                                        type: "LISTING_PREPARATION_FORM_CHANGE",
                                        payload: form
                                    })}
                                />
                            }
                            {isDetailsSectionSelected("specifics") && 
                                <CRMListingSpecificsForm
                                    listingPageData={props.state.forms.listing_page.data}
                                    onChange={(form) => props.dispatch({
                                        type: "LISTING_PREPARATION_FORM_CHANGE",
                                        payload: form
                                    })}
                                />
                            }
                            {isDetailsSectionSelected("marketing") && 
                                <CRMListingMarketingFormContainer
                                    {...props}
                                />
                            }
                            
                            {isDetailsSectionSelected("signs") && 
                                <CRMListingSignsContainer
                                    {...props}
                                />
                            }
                            
                            {isDetailsSectionSelected("documents") &&
                                <>
                                    <CRMListingDocumentManagementsForm
                                        listingForm={props.state.forms.listing_page.data.output.form}
                                        listingReadOnly={props.state.forms.listing_page.data.output.read_only}
                                        onChange={(form) => props.dispatch({
                                            type: "LISTING_PREPARATION_FORM_CHANGE",
                                            payload: form
                                        })}
                                    />
                                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                                        <CRMTitleSubSection>
                                            Documents
                                        </CRMTitleSubSection>
                                        <CRMLink
                                            href="https://find-energy-certificate.digital.communities.gov.uk/"
                                            linkStyle="normal"
                                            target="_blank"
                                        >
                                            <WeightBold>Open EPC Register (in new tab)</WeightBold>
                                        </CRMLink>
                                        <CRMListingFileUpload
                                            listingId={props.state.forms.listing_page.data.output.form.original.id}
                                            listingStatus={props.state.forms.listing_page.data.output.read_only.status}
                                            files={props.state.forms.listing_page.data.output.read_only.documents}
                                            onUpload={(file) => {
                                                return props.dispatch({
                                                    type: "LISTING_PAGE_ADD_DOCUMENTS",
                                                    payload: file
                                                });
                                            }}
                                            onRemove={(file) => {
                                                return props.dispatch({
                                                    type: "LISTING_PAGE_REMOVE_DOCUMENT",
                                                    payload: file
                                                });
                                            }}
                                            onUpdate={(form) => {
                                                return props.dispatch({
                                                    type: "LISTING_PAGE_UPDATE_DOCUMENT",
                                                    payload: form
                                                });
                                            }}
                                        />
                                    </SpacingColumn>
                                </>
                            }
                            {isDetailsSectionSelected("selling_process") &&
                                <CRMListingSellingProcessForm
                                    listingPageData={props.state.forms.listing_page.data}
                                    visibleSections={props.state.routes.queryParams.VIEW_CRM_LISTING.visible_sections}
                                    onChange={(form) => props.dispatch({
                                        type: "LISTING_PREPARATION_FORM_CHANGE",
                                        payload: form
                                    })}
                                />
                            }
                            {isDetailsSectionSelected("competing_agent") &&
                                <CRMListingCompetingAgentForm
                                    listingForm={props.state.forms.listing_page.data.output.form}
                                    onChange={(form) => props.dispatch({
                                        type: "LISTING_PREPARATION_FORM_CHANGE",
                                        payload: form
                                    })}
                                />
                            }
                            {isDetailsSectionSelected("conveyancing") && 
                                <CRMListingConveyancingForm
                                    listingForm={props.state.forms.listing_page.data.output.form}
                                    onChange={(form) => props.dispatch({
                                        type: "LISTING_PREPARATION_FORM_CHANGE",
                                        payload: form
                                    })}
                                />
                            }
                            {isDetailsSectionSelected("key_management") && 
                                <CRMListingKeyManagementForm
                                    listingPageData={props.state.forms.listing_page.data}
                                    onChange={(form) => props.dispatch({
                                        type: "LISTING_PREPARATION_FORM_CHANGE",
                                        payload: form
                                    })}
                                />
                            }
                        </CRMSpacingColumn>
                    </CRMPadding>
                </CRMCardOutside>
            </SpacingColumn>
            
            {/* COLUMN - DATA (PLACEHOLDER) */}
            <div></div>
        </SpacingRow>
    );
};
