import { filter, map, switchMap, tap } from "rxjs/operators";
import { TActionsDefinitionsList } from "./TAction";
import { action, routeAction } from "./actionFunctions";
import { Observable, bindCallback, from, of } from "rxjs";
import { PublicPropertyReportPage, TPublicPropertyReportCreateForm, TPublicPropertyReportTitleSearchResponse, TLandRegistrySearchPropertiesResponseSuccessOverride, TPublicPropertyReportAddressSearchForm, TPublicPropertyReportPageView } from "../../../../domain/codecs/form/PublicPropertyReportForm";
import { formOperation } from "../../wrappers/formOperation";
import { TStateCodec } from "../../../../domain/codecs/state";
import { TCodecLens } from "../../../../shared/src/codecs/codecLens";
import { TCodecSetState, TGetCodecState } from "../applyActions";
import { pipe } from "fp-ts/lib/function";
import { array, either, option } from "fp-ts";
import { required } from "../../../../shared/src/codecs/types/required";
import { string } from "../../../../shared/src/codecs/types/string";
import { union } from "../../../../shared/src/codecs/types/union";
import { nullCodec } from "../../../../shared/src/codecs/types/nullCodec";
import { doesErrorKeyExist } from "../../../../shared/src/codecs/errors";

declare var grecaptcha: {
    ready: () => void,
    execute: (publicKey: string, p: {action: string}) => Promise<string>,
};

const recaptchaReady$ = () => bindCallback(grecaptcha.ready)();

const recaptchaExecute$ = () => from(grecaptcha.execute(env.REACT_APP_RECAPTCHA_PUBLIC_KEY, {action: "CreatePublicPropertyReport"}));

const LocalStorageItem = required({
    first_name: string(),
    last_name: string(),
    email: union([nullCodec(), string()]),
    phone_number: union([nullCodec(), string()]),
});

const setView = (
    view: TPublicPropertyReportPageView,
    lens: TCodecLens<TStateCodec, TStateCodec>,
    set: TCodecSetState,
) =>
    set(lens.public_property_report_page.view.set(view));

const submitPropertyReportTitleSelectForm$ = (
    addressId: number,
    lens: TCodecLens<TStateCodec, TStateCodec>,
    set: TCodecSetState,
    get: TGetCodecState,
) =>
    of(lens.public_property_report_page.form.children.results.where(({edited}) => edited.id === addressId).get()(get()))
        .pipe(
            map((form) => ({
                ...form,
                status: "submitting" as "submitting",
            })),
            tap((form) => set(lens.public_property_report_page.form.children.results.where(({edited}) => edited.id === addressId).set(form))),
            switchMap((form) => formOperation("GetPublicPropertyReportTitleNumbers", form)),
            tap((response) => set(lens.public_property_report_page.form.children.results.where(({edited}) => edited.id === addressId).set(response))),
            tap((response) => set(lens.public_property_report_page.visible_title_number.set(
                response.children.response?._tag === "success"
                    && response.children.response.results.length === 1
                    ? response.children.response.results[0].edited.title_number
                    : null,
            ))),
            switchMap((response) =>
                response.children.response?._tag === "success"
                    && response.children.response.results.length === 1
                    ? submitPropertyReportCreateForm$(
                        response.children.response.results[0].edited.title_number,
                        lens,
                        set,
                        get
                    )
                    : of(setView("title_select", lens, set))
            ),
        );

const submitPropertyReportCreateForm$ = (
    titleNumber: string,
    lens: TCodecLens<TStateCodec, TStateCodec>,
    set: TCodecSetState,
    get: TGetCodecState,
) =>
    of(lens.public_property_report_page.form.children.results.where(({edited}) => edited.id === lens.public_property_report_page.selected_address_id.get()(get())).children.response.get()(get()))
        .pipe(
            filter<TPublicPropertyReportTitleSearchResponse | null, TLandRegistrySearchPropertiesResponseSuccessOverride>((response): response is TLandRegistrySearchPropertiesResponseSuccessOverride =>
                response?._tag === "success"
                // Don't perform this request if we already have the response for it
                && pipe(
                    response.results,
                    array.filter((result) =>
                        result.edited.title_number === titleNumber
                        && result.children.response !== null
                    ),
                ).length === 0
            ),
            map((response) =>
                pipe(
                    response.results,
                    array.map((result) =>
                        result.edited.title_number === titleNumber
                            ? {
                                ...result,
                                status: "submitting" as "submitting",
                            }
                            : result,
                    ),
                    (results) => ({
                        ...response,
                        results,
                    }),
                )
            ),
            tap((response) => set(lens.public_property_report_page.form.children.results.where(({edited}) => edited.id === lens.public_property_report_page.selected_address_id.get()(get())).children.response.set(response))),
            tap(() => setView("results", lens, set)),
            map(() => lens.public_property_report_page.form.children.results.where(({edited}) => edited.id === lens.public_property_report_page.selected_address_id.get()(get())).children.response.get()(get())),
            map((response) =>
                response?._tag === "success"
                    ? pipe(
                        response.results,
                        array.findFirst((result) => result.edited.title_number === titleNumber)
                    )
                    : option.none,
            ),
            switchMap((formOption) =>
                pipe(
                    formOption,
                    option.fold<TPublicPropertyReportCreateForm, Observable<undefined | TPublicPropertyReportTitleSearchResponse | null>>(
                        () => of(undefined),
                        (form) =>
                            recaptchaReady$()
                                .pipe(
                                    switchMap(recaptchaExecute$),
                                    switchMap((recaptcha_token) => formOperation("CreatePublicPropertyReport", {
                                        ...form,
                                        edited: {
                                            ...form.edited,
                                            recaptcha_token,
                                        },
                                    })),
                                    map((r) =>
                                        pipe(
                                            lens.public_property_report_page.form.children.results.where(({edited}) => edited.id === lens.public_property_report_page.selected_address_id.get()(get())).children.response.get()(get()),
                                            (response) =>
                                                response?._tag === "success"
                                                    ? pipe(
                                                        response.results,
                                                        array.map((result) =>
                                                            result.edited.title_number === r.edited.title_number
                                                                ? r
                                                                : result,
                                                        ),
                                                        (results) => ({
                                                            ...response,
                                                            results,
                                                        }),
                                                    )
                                                    : response,
                                        )
                                    ),
                                    tap((response) => set(lens.public_property_report_page.form.children.results.where(({edited}) => edited.id === lens.public_property_report_page.selected_address_id.get()(get())).children.response.set(response))),
                                    tap(() =>
                                        localStorage.setItem("public_property_report_user", JSON.stringify({
                                            first_name: form.edited.introducer.first_name,
                                            last_name: form.edited.introducer.last_name,
                                            email: form.edited.introducer.email,
                                            phone_number: form.edited.introducer.phone_number,
                                        })),
                                    ),
                                )
                    )
                )
            ),
        );

const submitAddressSearchForm$ = (
    form: TPublicPropertyReportAddressSearchForm,
    lens: TCodecLens<TStateCodec, TStateCodec>,
    set: TCodecSetState,
    get: TGetCodecState,
) =>
    of(form)
        .pipe(
            map((f) => lens.public_property_report_page.form.set({
                ...f,
                status: "submitting",
            })),
            tap(set),
            switchMap(() => formOperation("GetPublicPropertyReportAddressSearch", lens.public_property_report_page.form.get()(get()))),
            tap((response) => set(lens.public_property_report_page.form.set(response))),
            tap((response) => set(lens.public_property_report_page.selected_address_id.set(
                response.children.results.length === 1
                ? response.children.results[0].edited.id
                : null,
            ))),
            switchMap((response) =>
                response.status === "success" && response.children.results.length === 1 ? submitPropertyReportTitleSelectForm$(
                    response.children.results[0].edited.id,
                    lens,
                    set,
                    get
                )
                : response.status === "success" ? of(setView("address_select", lens, set))
                : (
                    response.status === "validationError"
                    && (
                        doesErrorKeyExist("edited.deceased_name", response.validationErrors)
                        || doesErrorKeyExist("edited.building_number_name", response.validationErrors)
                        || doesErrorKeyExist("edited.postcode", response.validationErrors)
                        || doesErrorKeyExist("edited.introducer.first_name", response.validationErrors)
                        || doesErrorKeyExist("edited.introducer.last_name", response.validationErrors)
                        || doesErrorKeyExist("edited.introducer.email", response.validationErrors)
                        || doesErrorKeyExist("edited.introducer.phone_number", response.validationErrors)
                    )
                ) ? of(setView("form_initial", lens, set))
                : (
                    response.status === "validationError"
                    && (
                        doesErrorKeyExist("edited.executor_or_beneficiary.is_executor", response.validationErrors)
                        || doesErrorKeyExist("edited.executor_or_beneficiary.first_name", response.validationErrors)
                        || doesErrorKeyExist("edited.executor_or_beneficiary.last_name", response.validationErrors)
                        || doesErrorKeyExist("edited.executor_or_beneficiary.email", response.validationErrors)
                        || doesErrorKeyExist("edited.executor_or_beneficiary.phone_number", response.validationErrors)
                    )
                ) ? of(setView("form_executor_or_beneficiary", lens, set))
                : of(setView("form_initial", lens, set))
            ),
        );

export const actions: TActionsDefinitionsList = [
    routeAction("VIEW_PROBATE_PROPERTY_REPORT_PAGE", (obs$, lens, set, get) => {
        obs$
            .pipe(
                map((payload) => lens.public_property_report_page.set({
                    ...PublicPropertyReportPage.newDefault(),
                    form: {
                        ...PublicPropertyReportPage.newDefault().form,
                        edited: {
                            ...PublicPropertyReportPage.newDefault().form.edited,
                            introducer_id: payload.queryStringParams.introducer_id || null,
                            introducer: {
                                ...PublicPropertyReportPage.newDefault().form.edited.introducer,
                                ...pipe(
                                    localStorage.getItem("public_property_report_user"),
                                    (localStorageValue) => JSON.parse(localStorageValue || "{}"),
                                    LocalStorageItem.decode,
                                    either.getOrElse(LocalStorageItem.newDefault),
                                ),
                            },
                        },
                    },
                    view: "form_initial",
                })),
                tap(set),
            ).subscribe();
    }),
    action("PUBLIC_PROPERTY_REPORT_ADDRESS_SEARCH_CHANGE", (obs$: Observable<TPublicPropertyReportAddressSearchForm>, lens, set, get) => {
        obs$
            .pipe(
                map(lens.public_property_report_page.form.set),
                tap(set),
            )
            .subscribe();
    }),
    action("PUBLIC_PROPERTY_REPORT_ADDRESS_SEARCH_SUBMIT", (obs$: Observable<undefined>, lens, set, get) => {
        obs$
            .pipe(
                switchMap(() => submitAddressSearchForm$(lens.public_property_report_page.form.get()(get()), lens, set, get)),
            )
            .subscribe();
    }),
    action("PUBLIC_PROPERTY_REPORT_SELECTED_ADDRESS_ID_CHANGE", (obs$: Observable<number>, lens, set, get) => {
        obs$
            .pipe(
                tap((addressId) => set(lens.public_property_report_page.selected_address_id.set(addressId))),
                switchMap((addressId) =>
                    submitPropertyReportTitleSelectForm$(
                        addressId,
                        lens,
                        set,
                        get
                    ),
                ),
            )
            .subscribe();
    }),
    action("PUBLIC_PROPERTY_REPORT_VISIBLE_TITLE_NUMBER_CHANGE", (obs$: Observable<string>, lens, set, get) => {
        obs$
            .pipe(
                tap((titleNumber) => set(lens.public_property_report_page.visible_title_number.set(titleNumber))),
                switchMap((titleNumber) =>
                    submitPropertyReportCreateForm$(
                        titleNumber,
                        lens,
                        set,
                        get
                    ),
                ),
            )
            .subscribe();
    }),
    action("PUBLIC_PROPERTY_REPORT_INITIAL_FORM_CONTINUE_PRESSED", (obs$: Observable<undefined>, lens, set, get) => {
        obs$
            .pipe(
                map(() => lens.public_property_report_page.form.get()(get())),
                switchMap((form) =>
                    form.edited.transaction_type !== null
                    && form.edited.wants_valuation !== null
                        ? submitAddressSearchForm$(form, lens, set, get)
                        : of(setView("form_transaction_type", lens, set))
                ),
            )
            .subscribe();
    }),
    action("PUBLIC_PROPERTY_REPORT_TRANSACTION_TYPE_CHANGE", (obs$: Observable<TPublicPropertyReportAddressSearchForm>, lens, set, get) => {
        obs$
            .pipe(
                map((form) => ({
                    ...form,
                    edited: {
                        ...form.edited,
                        wants_valuation:
                            form.edited.transaction_type === "sale"
                                ? true
                                : form.edited.wants_valuation
                    }
                })),
                switchMap((form) =>
                    of(set(lens.public_property_report_page.form.set(form)))
                        .pipe(
                            tap(() =>
                                form.edited.wants_valuation
                                    ? setView("form_executor_or_beneficiary", lens, set)
                                    : setView("form_wants_valuation", lens, set)
                            )
                        )
                ),
            )
            .subscribe();
    }),
    action("PUBLIC_PROPERTY_REPORT_WANTS_VALUATION_CHANGE", (obs$: Observable<TPublicPropertyReportAddressSearchForm>, lens, set, get) => {
        obs$
            .pipe(
                tap((form) => set(lens.public_property_report_page.form.set(form))),
                switchMap((form) =>
                    form.edited.wants_valuation
                        ? of(setView("form_executor_or_beneficiary", lens, set))
                        : submitAddressSearchForm$(form, lens, set, get)
                ),
            )
            .subscribe();
    }),
];
