import React from "react";
import { TContainerStateProps } from "./../../state/TContainerStateProps";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import {
    CaseContractDetails, CasesPurchasePaymentMethodDetails, CaseNonStandardUndertakingsExist,
    CaseNonStandardUndertakingsDetails, CasesMortgageLenderName, CasesSearchesRequired,
    CaseTransferDetails, CaseTransferIntendedAddressAndAdditionalProvisions, CasesPurchaseLenderDuelRepConflictDetails,
    CasesSellerPurchasingSearches, CasesBuyerNotPayingForSearches, CaseConditionsAndFixtureAndFittingsNotes,
    CaseConsiderationTypeDetails, CaseConsiderationTypeOtherDetails, CaseJointOwnershipOtherDetails, CaseKeyPlans, CaseDeedPlans, CaseContractNoTA10AndWetInkTransfer, CaseAdditionalSpecialConditionsForContract, CaseContractOccupierDetails, CaseContractASTDetails
} from "../../../../domain/codecs/CasesContractBlock";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMInputLabelAndErrorWrapComponent } from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMDeferButtonComponent } from "../../components/CRMDeferButtonComponent/CRMDeferButtonComponent";
import { CRMSpacingRow } from "../../components/CRMSpacingRow/CRMSpacingRow";
import { CRMDropdownComponent } from "../../components/CRMDropdownComponent/CRMDropdownComponent";
import { CRMInputCurrency } from "../../components/CRMInputCurrency/CRMInputCurrency";
import CRMInputGeneralComponent from "../../components/CRMInputs/CRMInputGeneralComponent/CRMInputGeneralComponent";
import { CRMRadioListComponent } from "../../components/CRMInputs/CRMRadioListComponent/CRMRadioListComponent";
import { pipe } from "fp-ts/lib/function";
import { array, option } from "fp-ts";
import { CaseLenderDictionary } from "../../../../domain/codecs/CasesLenderNameOnPanel";
import { CRMNoticeBoxComponent } from "../../components/CRMNoticeBoxComponent/CRMNoticeBoxComponent";
import { TTransactionType1 } from "../../../../domain/codecs/TransactionType";
import { DateTime } from "luxon";
import { CRMJointOwnershipHeldAsUserPreferences } from "../../components/Complex/CRMJointOwnershipHeldAsUserPreferences/CRMJointOwnershipHeldAsUserPreferences";
import { CRMInfoBox } from "../../components/Simple/CRMInfoBox/CRMInfoBox";
import { CRMColors } from "../../models/CRMColors";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { CRMSpacing } from "../../models/CRMSpacing";
import { CRMParagraph } from "../../components/Simple/CRMParagraph/CRMParagraph";
import { FontRoboto } from "../../components/BuildingBlocks/FontRoboto";
import { Text } from "../../components/BuildingBlocks/Text";
import { CRMFontSizes } from "../../models/CRMFontSizes";

const CRMLegalCaseDetailsContractDetailsContainer = (props: TContainerStateProps): JSX.Element => {
    const isInstructed = !!props.state.forms.contracts_block.children.is_instructed;

    const isIntroducerPaidAReferral = (introducerId: string): boolean =>
        pipe(
            props.state.forms.contracts_block.children.introducers,
            array.findFirst((introducer) =>
                introducer.id === introducerId
                && introducer.is_paid_referral_fee
            ),
            option.fold(
                () => false,
                () => true,
            )
        );

    const getTransactionType = (): TTransactionType1 => props.state.forms.contracts_block.children.details.edited.transaction_type;

    const getIsMortgageOrRemortgage = (): boolean => getTransactionType() === "remortgage" || props.state.forms.contracts_block.children.details.edited.purchase_payment_method === "mortgage"

    const hasUnregisteredProperties = pipe(
        props.state.forms.contracts_block.children.properties,
        array.findFirst((property) => property.edited.is_registered !== true),
        option.isSome
    );

    return <CRMAutosaveIndicatorWrap
        status={props.state.forms.contracts_block.children.details.status}
    >
        <CRMTitleSection>
            Contract Details
        </CRMTitleSection>
        <CRMSpacer size="large" />

        <CRMSpacingColumn spacing="medium">

            <CRMInputLabelAndErrorWrapComponent label="Defer get contract details job">
                <CRMDeferButtonComponent
                    value={props.state.forms.contracts_block.children.details.edited.get_contract_details_job_defer}
                    onChange={(get_contract_details_job_defer) => props.dispatch({
                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                        payload: {
                            ...props.state.forms.contracts_block.children.details,
                            edited: {
                                ...props.state.forms.contracts_block.children.details.edited,
                                get_contract_details_job_defer,
                            }
                        }
                    })}
                    displayError={false}
                />
            </CRMInputLabelAndErrorWrapComponent>

            {getTransactionType() === "sale" && <>
                <CRMCodecEditForm
                    codec={CasesSellerPurchasingSearches}
                    model={props.state.forms.contracts_block.children.details.edited}
                    validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                    columns={1}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                        payload: {
                            ...props.state.forms.contracts_block.children.details,
                            edited: {
                                ...props.state.forms.contracts_block.children.details.edited,
                                ...edited,
                            }
                        }
                    })}
                    showDeferDateLabels={true}
                />

                {props.state.forms.contracts_block.children.details.edited.seller_to_purchase_searches && <CRMCodecEditForm
                    codec={CasesBuyerNotPayingForSearches}
                    model={props.state.forms.contracts_block.children.details.edited}
                    validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                    columns={1}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                        payload: {
                            ...props.state.forms.contracts_block.children.details,
                            edited: {
                                ...props.state.forms.contracts_block.children.details.edited,
                                ...edited,
                            }
                        }
                    })}
                    showDeferDateLabels={true}
                />}
            </>}

            <CRMSpacingRow spacing="medium" alignItems="flex-start" childSize="1fr 1fr">
                <CRMInputLabelAndErrorWrapComponent label="Does this case have a marketing campaign?">
                    <CRMRadioListComponent
                        options={[
                            {
                                value: true,
                                text: "Yes",
                            },
                            {
                                value: false,
                                text: "No",
                            },
                        ]}
                        value={props.state.forms.contracts_block.children.details.edited.has_marketing_campaign}
                        direction="row"
                        onChange={(has_marketing_campaign) => props.dispatch({
                            type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                            payload: {
                                ...props.state.forms.contracts_block.children.details,
                                edited: {
                                    ...props.state.forms.contracts_block.children.details.edited,
                                    has_marketing_campaign,
                                    introducer_id: has_marketing_campaign ? props.state.forms.contracts_block.children.details.edited.introducer_id : null,
                                }
                            }
                        })}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                {props.state.forms.contracts_block.children.details.edited.has_marketing_campaign && <CRMInputLabelAndErrorWrapComponent label="Campaign">
                    <CRMDropdownComponent
                        options={[
                            {
                                value: "",
                                label: "Not set",
                            },
                            ...props.state.forms.contracts_block.children.marketing_campaigns.map(({ id, name }) => ({
                                value: id,
                                label: name,
                            })),
                        ]}
                        value={props.state.forms.contracts_block.children.details.edited.marketing_campaign_id || ""}
                        onChange={(marketing_campaign_id) => props.dispatch({
                            type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                            payload: {
                                ...props.state.forms.contracts_block.children.details,
                                edited: {
                                    ...props.state.forms.contracts_block.children.details.edited,
                                    marketing_campaign_id: marketing_campaign_id || null,
                                }
                            }
                        })}
                        displayError={false}
                    />
                </CRMInputLabelAndErrorWrapComponent>}
            </CRMSpacingRow>

            <CRMSpacingRow spacing="medium" alignItems="flex-start" childSize="1fr 1fr">
                <CRMInputLabelAndErrorWrapComponent label="Has this case been referred to us?">
                    <CRMRadioListComponent
                        options={[
                            {
                                value: true,
                                text: "Yes",
                            },
                            {
                                value: false,
                                text: "No",
                            },
                        ]}
                        value={props.state.forms.contracts_block.children.details.edited.has_introducer}
                        direction="row"
                        onChange={(has_introducer) => props.dispatch({
                            type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                            payload: {
                                ...props.state.forms.contracts_block.children.details,
                                edited: {
                                    ...props.state.forms.contracts_block.children.details.edited,
                                    has_introducer,
                                    introducer_id: has_introducer ? props.state.forms.contracts_block.children.details.edited.introducer_id : null,
                                    introducer_referral_fee_gbp_pence: has_introducer ? props.state.forms.contracts_block.children.details.edited.introducer_referral_fee_gbp_pence : null,
                                }
                            }
                        })}
                    />
                </CRMInputLabelAndErrorWrapComponent>

                {props.state.forms.contracts_block.children.details.edited.has_introducer && <CRMInputLabelAndErrorWrapComponent
                    label="Introducer"
                >
                    <CRMDropdownComponent
                        options={[
                            {
                                value: "",
                                label: "Not set",
                            },
                            ...props.state.forms.contracts_block.children.introducers.map(({ id, name }) => ({
                                value: id,
                                label: name,
                            })),
                        ]}
                        value={props.state.forms.contracts_block.children.details.edited.introducer_id || ""}
                        displayError={false}
                        onChange={(introducer_id) => props.dispatch({
                            type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                            payload: {
                                ...props.state.forms.contracts_block.children.details,
                                edited: {
                                    ...props.state.forms.contracts_block.children.details.edited,
                                    introducer_id: introducer_id || null,
                                    introducer_referral_fee_gbp_pence: isIntroducerPaidAReferral(introducer_id) ? props.state.forms.contracts_block.children.details.edited.introducer_referral_fee_gbp_pence : null,
                                }
                            }
                        })}
                    />
                </CRMInputLabelAndErrorWrapComponent>}
            </CRMSpacingRow>

            {props.state.forms.contracts_block.children.details.edited.introducer_id &&
                <>
                    <CRMSpacingRow spacing="medium" alignItems="flex-start" childSize="1fr 1fr">
                        {isInstructed &&
                            <CRMInputLabelAndErrorWrapComponent label="Referral fee">
                                <CRMInputCurrency
                                    value={props.state.forms.contracts_block.children.details.edited.introducer_referral_fee_gbp_pence}
                                    mode="pence"
                                    onChange={(introducer_referral_fee_gbp_pence) => props.dispatch({
                                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                                        payload: {
                                            ...props.state.forms.contracts_block.children.details,
                                            edited: {
                                                ...props.state.forms.contracts_block.children.details.edited,
                                                introducer_referral_fee_gbp_pence,
                                            }
                                        }
                                    })}
                                    // Disable the referral fee input for introducers that don't support being paid referral fees
                                    disabled={!isIntroducerPaidAReferral(props.state.forms.contracts_block.children.details.edited.introducer_id)}
                                />
                            </CRMInputLabelAndErrorWrapComponent>
                        }

                        <CRMInputLabelAndErrorWrapComponent label="Sub introducer">
                            <CRMInputGeneralComponent
                                inputType="text"
                                value={props.state.forms.contracts_block.children.details.edited.sub_introducer}
                                onChange={(sub_introducer) => props.dispatch({
                                    type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                                    payload: {
                                        ...props.state.forms.contracts_block.children.details,
                                        edited: {
                                            ...props.state.forms.contracts_block.children.details.edited,
                                            sub_introducer,
                                        }
                                    }
                                })}
                                displayError={false}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                    </CRMSpacingRow>
                    <CRMSpacingRow spacing="medium" alignItems="flex-start" childSize="1fr 1fr">
                        <CRMInputLabelAndErrorWrapComponent label="Has the referral fee been paid?">
                            <CRMRadioListComponent
                                options={[
                                    {
                                        value: true,
                                        text: "Yes",
                                    },
                                    {
                                        value: false,
                                        text: "No",
                                    },
                                ]}
                                value={props.state.forms.contracts_block.children.details.edited.referral_fee_paid ? true : false}
                                direction="row"
                                onChange={(referral_fee_paid) => props.dispatch({
                                    type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                                    payload: {
                                        ...props.state.forms.contracts_block.children.details,
                                        edited: {
                                            ...props.state.forms.contracts_block.children.details.edited,
                                            referral_fee_paid: referral_fee_paid ? DateTime.utc().toISO() : null,
                                        }
                                    }
                                })}
                            />
                        </CRMInputLabelAndErrorWrapComponent>

                        {/* REFERRAL FEE DEFER */}
                        <CRMInputLabelAndErrorWrapComponent label="Defer pay referral fee job">
                            <CRMDeferButtonComponent
                                value={props.state.forms.contracts_block.children.details.edited.referral_fee_paid_defer}
                                onChange={(referral_fee_paid_defer) => props.dispatch({
                                    type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                                    payload: {
                                        ...props.state.forms.contracts_block.children.details,
                                        edited: {
                                            ...props.state.forms.contracts_block.children.details.edited,
                                            referral_fee_paid_defer,
                                        }
                                    }
                                })}
                                displayError={false}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                    </CRMSpacingRow>
                </>
            }

            <CRMSpacingRow spacing="medium" alignItems="flex-start" childSize="1fr 1fr">
                <CRMInputLabelAndErrorWrapComponent label="Price">
                    <CRMInputCurrency
                        value={props.state.forms.contracts_block.children.details.edited.price_pence}
                        mode="pence"
                        onChange={(price_pence) => props.dispatch({
                            type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                            payload: {
                                ...props.state.forms.contracts_block.children.details,
                                edited: {
                                    ...props.state.forms.contracts_block.children.details.edited,
                                    price_pence,
                                }
                            }
                        })}
                    />
                </CRMInputLabelAndErrorWrapComponent>
            </CRMSpacingRow>

            <CRMSpacingRow spacing="medium" alignItems="flex-start" childSize="1fr 1fr">
                {props.state.forms.contracts_block.children.case_status !== "quote"
                    ? <>
                        <CRMInputLabelAndErrorWrapComponent label="Deposit according to contract">
                            <CRMInputCurrency
                                value={props.state.forms.contracts_block.children.details.edited.deposit_according_to_contract_pence}
                                mode="pence"
                                onChange={(deposit_according_to_contract_pence) => props.dispatch({
                                    type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                                    payload: {
                                        ...props.state.forms.contracts_block.children.details,
                                        edited: {
                                            ...props.state.forms.contracts_block.children.details.edited,
                                            deposit_according_to_contract_pence,
                                        }
                                    }
                                })}
                            />
                        </CRMInputLabelAndErrorWrapComponent>

                        <CRMInputLabelAndErrorWrapComponent label="Balance for contract">
                            <CRMInputCurrency
                                value={props.state.forms.contracts_block.children.details.edited.balance_for_contract_pence}
                                mode="pound"
                                onChange={(balance_for_contract_pence) => props.dispatch({
                                    type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                                    payload: {
                                        ...props.state.forms.contracts_block.children.details,
                                        edited: {
                                            ...props.state.forms.contracts_block.children.details.edited,
                                            balance_for_contract_pence,
                                        }
                                    }
                                })}
                            />
                        </CRMInputLabelAndErrorWrapComponent>
                    </>
                    : <div></div>}
            </CRMSpacingRow>

            <CRMSpacingRow spacing="medium" alignItems="flex-start" childSize="1fr 1fr">
                <CRMInputLabelAndErrorWrapComponent label="Chattels Price for Contract">
                    <CRMInputCurrency
                        value={props.state.forms.contracts_block.children.details.edited.chattels_price_for_contract_pence}
                        mode="pence"
                        onChange={(chattels_price_for_contract_pence) => props.dispatch({
                            type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                            payload: {
                                ...props.state.forms.contracts_block.children.details,
                                edited: {
                                    ...props.state.forms.contracts_block.children.details.edited,
                                    chattels_price_for_contract_pence,
                                }
                            }
                        })}
                    />
                </CRMInputLabelAndErrorWrapComponent>
            </CRMSpacingRow>

            {props.state.forms.contracts_block.children.case_status !== "quote" && <>
                <CRMCodecEditForm
                    codec={CaseConditionsAndFixtureAndFittingsNotes}
                    model={props.state.forms.contracts_block.children.details.edited}
                    validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                    columns={2}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                        payload: {
                            ...props.state.forms.contracts_block.children.details,
                            edited: {
                                ...props.state.forms.contracts_block.children.details.edited,
                                ...edited,
                            }
                        }
                    })}
                    showDeferDateLabels={true}
                />

                <CRMCodecEditForm
                    codec={CaseConsiderationTypeDetails}
                    model={props.state.forms.contracts_block.children.details.edited}
                    validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                    columns={1}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                        payload: {
                            ...props.state.forms.contracts_block.children.details,
                            edited: {
                                ...props.state.forms.contracts_block.children.details.edited,
                                ...edited,
                            }
                        }
                    })}
                    showDeferDateLabels={true}
                />

                {props.state.forms.contracts_block.children.details.edited.consideration_type === "other" && <CRMCodecEditForm
                    codec={CaseConsiderationTypeOtherDetails}
                    model={props.state.forms.contracts_block.children.details.edited}
                    validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                    columns={1}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                        payload: {
                            ...props.state.forms.contracts_block.children.details,
                            edited: {
                                ...props.state.forms.contracts_block.children.details.edited,
                                ...edited,
                            }
                        }
                    })}
                    showDeferDateLabels={true}
                />}

                <CRMCodecEditForm
                    codec={CaseContractOccupierDetails}
                    model={props.state.forms.contracts_block.children.details.edited}
                    validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                    columns={2}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                        payload: {
                            ...props.state.forms.contracts_block.children.details,
                            edited: {
                                ...props.state.forms.contracts_block.children.details.edited,
                                ...edited,
                            }
                        }
                    })}
                    showDeferDateLabels={true}
                />

                {props.state.forms.contracts_block.children.details.edited.vacant_possession_or_ast === "ast" &&
                    <CRMCodecEditForm
                        codec={CaseContractASTDetails}
                        model={props.state.forms.contracts_block.children.details.edited}
                        validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                        columns={1}
                        onChange={(edited) => props.dispatch({
                            type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                            payload: {
                                ...props.state.forms.contracts_block.children.details,
                                edited: {
                                    ...props.state.forms.contracts_block.children.details.edited,
                                    ...edited,
                                }
                            }
                        })}
                        showDeferDateLabels={true}
                    />
                }

                <CRMCodecEditForm
                    codec={CaseContractDetails}
                    model={props.state.forms.contracts_block.children.details.edited}
                    validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                    columns={2}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                        payload: {
                            ...props.state.forms.contracts_block.children.details,
                            edited: {
                                ...props.state.forms.contracts_block.children.details.edited,
                                ...edited,
                            }
                        }
                    })}
                    showDeferDateLabels={true}
                />

                {/* Purchase-, transfer-, remortgage-specific */}
                {(
                    getTransactionType() === "purchase"
                    || getTransactionType() === "transfer"
                    || getTransactionType() === "remortgage"
                ) && <>

                        {/* Payment method - cash/mortgage */}
                        {(getTransactionType() === "purchase" || getTransactionType() === "transfer")
                            && <CRMCodecEditForm
                                codec={CasesPurchasePaymentMethodDetails}
                                model={props.state.forms.contracts_block.children.details.edited}
                                validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                                columns={2}
                                onChange={(edited) => props.dispatch({
                                    type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                                    payload: {
                                        ...props.state.forms.contracts_block.children.details,
                                        edited: {
                                            ...props.state.forms.contracts_block.children.details.edited,
                                            ...edited,
                                        }
                                    }
                                })}
                            />
                        }

                        {/* Expected mortgage amount */}
                        {getIsMortgageOrRemortgage()
                            && <CRMSpacingRow spacing="medium" alignItems="flex-start" childSize="1fr 1fr">
                                <CRMInputLabelAndErrorWrapComponent label="Expected Mortgage Amount">
                                    <CRMInputCurrency
                                        value={props.state.forms.contracts_block.children.details.edited.expected_mortgage_amount_pence}
                                        mode="pence"
                                        onChange={(expected_mortgage_amount_pence) => props.dispatch({
                                            type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                                            payload: {
                                                ...props.state.forms.contracts_block.children.details,
                                                edited: {
                                                    ...props.state.forms.contracts_block.children.details.edited,
                                                    expected_mortgage_amount_pence,
                                                }
                                            }
                                        })}
                                    />
                                </CRMInputLabelAndErrorWrapComponent>
                            </CRMSpacingRow>
                        }

                        {/* Lender name */}
                        {getIsMortgageOrRemortgage()
                            && <CRMCodecEditForm
                                codec={CasesMortgageLenderName}
                                model={props.state.forms.contracts_block.children.details.edited}
                                validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                                columns={2}
                                onChange={(edited) => props.dispatch({
                                    type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                                    payload: {
                                        ...props.state.forms.contracts_block.children.details,
                                        edited: {
                                            ...props.state.forms.contracts_block.children.details.edited,
                                            ...edited,
                                        }
                                    }
                                })}
                            />
                        }

                        {/* Dual rep lender conflict details */}
                        {
                            (getTransactionType() === "purchase" || getTransactionType() === "transfer")
                            && props.state.forms.contracts_block.children.dual_rep.linked_case_form?.edited.id !== undefined
                            && !CaseLenderDictionary[props.state.forms.contracts_block.children.details.edited.mortgage_lender_name].allowsDualRep
                            && <>
                                <CRMNoticeBoxComponent>
                                    This lender does not support dual rep
                                </CRMNoticeBoxComponent>
                                <CRMCodecEditForm
                                    codec={CasesPurchaseLenderDuelRepConflictDetails}
                                    model={props.state.forms.contracts_block.children.details.edited}
                                    validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                                    showDeferDateLabels={true}
                                    columns={1}
                                    onChange={(edited) => props.dispatch({
                                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                                        payload: {
                                            ...props.state.forms.contracts_block.children.details,
                                            edited: {
                                                ...props.state.forms.contracts_block.children.details.edited,
                                                ...edited,
                                            }
                                        }
                                    })}
                                />
                            </>
                        }

                        {/* Searches */}
                        {(getTransactionType() === "purchase" || getTransactionType() === "transfer")
                            && <CRMCodecEditForm
                                codec={CasesSearchesRequired}
                                model={props.state.forms.contracts_block.children.details.edited}
                                validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                                columns={2}
                                onChange={(edited) => props.dispatch({
                                    type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                                    payload: {
                                        ...props.state.forms.contracts_block.children.details,
                                        edited: {
                                            ...props.state.forms.contracts_block.children.details.edited,
                                            ...edited,
                                        }
                                    }
                                })}
                            />
                        }
                    </>}


                {/* UnderTakings */}
                <CRMCodecEditForm
                    codec={CaseNonStandardUndertakingsExist}
                    model={props.state.forms.contracts_block.children.details.edited}
                    validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                    columns={2}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                        payload: {
                            ...props.state.forms.contracts_block.children.details,
                            edited: {
                                ...props.state.forms.contracts_block.children.details.edited,
                                ...edited,
                            }
                        }
                    })}
                />

                {
                    props.state.forms.contracts_block.children.details.edited.has_non_standard_undertakings &&
                    <CRMCodecEditForm
                        codec={CaseNonStandardUndertakingsDetails}
                        model={props.state.forms.contracts_block.children.details.edited}
                        validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                        columns={2}
                        onChange={(edited) => props.dispatch({
                            type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                            payload: {
                                ...props.state.forms.contracts_block.children.details,
                                edited: {
                                    ...props.state.forms.contracts_block.children.details.edited,
                                    ...edited,
                                }
                            }
                        })}
                    />
                }

                <CRMCodecEditForm
                    codec={CaseTransferDetails}
                    model={props.state.forms.contracts_block.children.details.edited}
                    validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                    columns={2}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                        payload: {
                            ...props.state.forms.contracts_block.children.details,
                            edited: {
                                ...props.state.forms.contracts_block.children.details.edited,
                                ...edited,
                            }
                        }
                    })}
                    showDeferDateLabels={true}
                />

                {props.state.forms.contracts_block.children.details.edited.joint_ownership_held_as === "on_trust" && <CRMCodecEditForm
                    codec={CaseJointOwnershipOtherDetails}
                    model={props.state.forms.contracts_block.children.details.edited}
                    validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                    columns={1}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                        payload: {
                            ...props.state.forms.contracts_block.children.details,
                            edited: {
                                ...props.state.forms.contracts_block.children.details.edited,
                                ...edited,
                            }
                        }
                    })}
                    showDeferDateLabels={true}
                />}

                {/* JOINT OWNERSHIP HELD AS USER PREFERENCES */}
                {props.state.forms.contracts_block.children.user_joint_ownership_held_as_preferences.are_questions_applicable &&
                    <CRMJointOwnershipHeldAsUserPreferences
                        preferences={props.state.forms.contracts_block.children.user_joint_ownership_held_as_preferences}
                    />
                }

                <CRMCodecEditForm
                    codec={CaseTransferIntendedAddressAndAdditionalProvisions}
                    model={props.state.forms.contracts_block.children.details.edited}
                    validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                    columns={1}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                        payload: {
                            ...props.state.forms.contracts_block.children.details,
                            edited: {
                                ...props.state.forms.contracts_block.children.details.edited,
                                ...edited,
                            }
                        }
                    })}
                    showDeferDateLabels={true}
                />

                <CRMInfoBox color={CRMColors.PRIMARY_16}>
                    <SpacingColumn spacing={CRMSpacing.MEDIUM}>
                        
                        <SpacingColumn spacing={CRMSpacing.TINY}>
                            <CRMParagraph>
                                Please enter special conditions starting on a new line and beginning with an <FontRoboto><Text size={CRMFontSizes.X_SMALL}>*</Text></FontRoboto>
                            </CRMParagraph>
                            <CRMParagraph>
                                <FontRoboto>
                                    <Text size={CRMFontSizes.X_SMALL}>
                                        &nbsp;&nbsp;* An example additional provision.
                                        <br/>
                                        &nbsp;&nbsp;* Another example additional provision.
                                    </Text>
                                </FontRoboto>
                            </CRMParagraph>
                            <CRMParagraph>
                                This is so that special conditions can be numbered correctly in the contract
                            </CRMParagraph>
                        </SpacingColumn>
                        
                    </SpacingColumn>
                </CRMInfoBox>

                <CRMCodecEditForm
                    codec={CaseAdditionalSpecialConditionsForContract}
                    model={props.state.forms.contracts_block.children.details.edited}
                    validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                    columns={1}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                        payload: {
                            ...props.state.forms.contracts_block.children.details,
                            edited: {
                                ...props.state.forms.contracts_block.children.details.edited,
                                ...edited,
                            }
                        }
                    })}
                    showDeferDateLabels={true}
                />

                <CRMCodecEditForm
                    codec={CaseKeyPlans}
                    model={props.state.forms.contracts_block.children.details.edited}
                    validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                    columns={1}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                        payload: {
                            ...props.state.forms.contracts_block.children.details,
                            edited: {
                                ...props.state.forms.contracts_block.children.details.edited,
                                ...edited,
                            }
                        }
                    })}
                    showDeferDateLabels={true}
                />

                {hasUnregisteredProperties &&
                <CRMCodecEditForm
                    codec={CaseDeedPlans}
                    model={props.state.forms.contracts_block.children.details.edited}
                    validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                    columns={1}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                        payload: {
                            ...props.state.forms.contracts_block.children.details,
                            edited: {
                                ...props.state.forms.contracts_block.children.details.edited,
                                ...edited,
                            }
                        }
                    })}
                    showDeferDateLabels={true}
                />}

                <CRMCodecEditForm
                    codec={CaseContractNoTA10AndWetInkTransfer}
                    model={props.state.forms.contracts_block.children.details.edited}
                    validationErrors={props.state.forms.contracts_block.children.details.validationErrors}
                    columns={2}
                    onChange={(edited) => props.dispatch({
                        type: "LEGAL_CASE_CONTRACTS_BLOCK_CHANGE",
                        payload: {
                            ...props.state.forms.contracts_block.children.details,
                            edited: {
                                ...props.state.forms.contracts_block.children.details.edited,
                                ...edited,
                            }
                        }
                    })}
                    showDeferDateLabels={true}
                />

            </>}
        </CRMSpacingColumn>
    </CRMAutosaveIndicatorWrap>;
};

export default CRMLegalCaseDetailsContractDetailsContainer;