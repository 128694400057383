import { literal } from "../../shared/src/codecs/types/literal";
import { union } from "../../shared/src/codecs/types/union";

export const CaseSellerReasonForSale1 = union([
    literal("normal"),
    literal("repossession"),
    literal("probate"),
    literal("deputyship"),
    literal("divorce"),
    literal("unknown"),
]);
