import React from "react";
import { CRMPopoutHeader } from "../../../Simple/CRMPopoutHeader/CRMPopoutHeader";
import { CRMBlockPopover } from "../../CRMBlock/CRMBlockPopover/CRMBlockPopover";
import { CRMSpacing } from "../../../../models/CRMSpacing";
import { CRMFilterSearchSelect } from "../../../Complex/CRMFilterSearchSelect/CRMFilterSearchSelect";
import { LegalEmailTemplates, TEmailTemplateRecord } from "./LegalEmailTemplates";
import { IFuzzySortOption } from "../../../../hooks/useFuzzysort";
import { CRMFilterAndSelectTinyArrowOptionWrap } from "../../../Simple/CRMFilterAndSelectTinyArrowOptionWrap/CRMFilterAndSelectTinyArrowOptionWrap";
import { CRMFuzzySortSearchHighlight } from "../../../Simple/CRMFuzzySortSearchHighlight/CRMFuzzySortSearchHighlight";
import { CRMEnquiryAttachment } from "../CRMEnquiryAttachment/CRMEnquiryAttachment";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { CRMParagraph } from "../../../Simple/CRMParagraph/CRMParagraph";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { Background } from "../../../BuildingBlocks/Background";
import { FontQuicksand } from "../../../BuildingBlocks/FontQuicksand";
import { Text } from "../../../BuildingBlocks/Text";
import { CRMFontSizes } from "../../../../models/CRMFontSizes";
import { CRMColors } from "../../../../models/CRMColors";

type TComposeEmailTemplatesPopoverProps = {
    onClose: () => void;
    onSelectTemplate: (template: TEmailTemplateRecord) => void
};

export const CRMComposeEmailTemplatesPopover = (props: React.PropsWithChildren<TComposeEmailTemplatesPopoverProps>): JSX.Element => {

    const PopoutOptions = LegalEmailTemplates.map(EmailTemplateRecordToListSearchOption(props.onSelectTemplate));

    return (
        <CRMBlockPopover
            padding={CRMSpacing.MEDIUM}
            top={CRMSpacing.TINY}
            height="720px" 
            maxHeight="720px"
        >
            <CRMPopoutHeader
                icon="arrow-left"
                onClick={props.onClose}
            >
                Select email template
            </CRMPopoutHeader>

            <CRMFilterSearchSelect
                searchStickyTopPosition="51px"
                searchPlaceholder="Search for a template"
                noMatchesText="There are no attachments to choose from."
                options={PopoutOptions}
            />
        </CRMBlockPopover>
    );
};

const EmailTemplateRecordToListSearchOption = (onClick: (template: TEmailTemplateRecord) => void) => 
    (template: TEmailTemplateRecord): IFuzzySortOption<string, TEmailTemplateRecord> => {
        const searchLabel = `To: ${template.to}, Acting for: ${template.acting_for}, Use case: ${template.use_case}`;

        return {
            label: searchLabel,
            value: template.content,
            meta: template,
            richLabelFormatting: (result, searchText) => (
                <CRMListSearchOption__EmailTemplate
                    result={result}
                    searchText={searchText}
                    template={template}
                    onClick={() => onClick(template)}
                />
            )
        }
    }
;


type TCRMListSearchOption__EmailTemplate<V extends string> = {
    result: Fuzzysort.KeyResult<IFuzzySortOption<V, TEmailTemplateRecord>>;
    template: TEmailTemplateRecord;
    searchText: string;
    onClick: () => void;
};

const CRMListSearchOption__EmailTemplate = <V extends string>(props: TCRMListSearchOption__EmailTemplate<V>): JSX.Element => {
    return (
        <CRMFilterAndSelectTinyArrowOptionWrap onClick={props.onClick}>
            <Background padding={`0px 0px ${CRMSpacing.TINY}`}>
                <SpacingColumn spacing={CRMSpacing.SMALL}>
                    {/* LABELS & TAG */}
                    <SpacingColumn spacing={CRMSpacing.TINY}>
                        {/* LABELS - TO & FOR */}
                        <SpacingRow 
                            spacing={CRMSpacing.MEDIUM}
                            childSize="200px 1fr"
                        >
                            <OptionLabel
                                label={"To:"}
                                value={props.template.to}
                                searchText={props.searchText}
                            />
                            <OptionLabel
                                label={"Acting for:"}
                                value={props.template.acting_for}
                                searchText={props.searchText}
                            />
                        </SpacingRow>

                        {/* TAG - USE CASE */}
                        <div>
                            <TagPill
                                label={props.template.use_case}
                                searchText={props.searchText}
                            />
                        </div>
                    </SpacingColumn>

                    {/* PARAGRAPH - CONTENT PREVIEW */}
                    <Background display="flex" padding={`0px ${CRMSpacing.TINY}`}>
                        <FontQuicksand>
                            <Text 
                                size={CRMFontSizes.SMALL} 
                                lineHeight={1}
                                weight={500}
                                color={CRMColors.NEUTRAL_2}
                            >
                                {props.template.content.slice(0, 100)}...
                            </Text>
                        </FontQuicksand>
                    </Background>
                </SpacingColumn>
            </Background>
        </CRMFilterAndSelectTinyArrowOptionWrap>
    );
}

type TOptionLabelProps = {
    label: string;
    value: string;
    searchText: string;
}

const OptionLabel = (props: TOptionLabelProps) => {
    return (
        <SpacingRow spacing={CRMSpacing.TINY}>
            <CRMParagraph>
                <WeightBold>
                    <CRMFuzzySortSearchHighlight
                        text={props.label}
                        searchText={props.searchText}
                    />
                </WeightBold>
            </CRMParagraph>
            <CRMParagraph>
                <CRMFuzzySortSearchHighlight
                    text={props.value}
                    searchText={props.searchText}
                />
            </CRMParagraph>
        </SpacingRow>
    )
}


type TTagPillProps = {
    label: string;
    searchText: string;
}

const TagPill = (props: TTagPillProps) => {
    return (
        <Background
            display="inline-flex"
            borderRadius={`100px`}
            color={CRMColors.PRIMARY_16}
            padding={`${CRMSpacing.TINY} ${CRMSpacing.SMALL}`}
        >
            <FontQuicksand>
                <Text 
                    size={CRMFontSizes.SMALL} 
                    lineHeight={1}
                    weight={500}
                    color={CRMColors.PRIMARY_2}
                >
                    <CRMFuzzySortSearchHighlight
                        text={props.label}
                        searchText={props.searchText}
                    />
                </Text>
            </FontQuicksand>
        </Background>
    )
}
