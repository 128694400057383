import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import { TAnyDecimalCodec } from "../../../../shared/src/codecs/types/decimal";

export const pickRandom = <I>(items: Array<I>): I => items[Math.floor(Math.random() * items.length)];

export const getRandomInt = (min: number, max: number) => 
    Math.floor(Math.random() * (max - min + 1)) + min
;

export const getRandomArraySlice = <T extends unknown>(arr: Array<T>) =>
    arr
        .sort(() => .5 - Math.random())
        .slice(0, getRandomInt(0, arr.length))
;

export const generateRandomArrayLength = <T extends unknown>(factory: (index: number, number: number) => T, max?: number, min?: number): Array<T> =>
    pipe(
        array.range(0, getRandomInt(min || 0, max || 15)),
        array.mapWithIndex(factory),
    )
;

export const mockBoolean = (): boolean => pickRandom([true, false]);