import React, { useState } from "react";
import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import { IFuzzySortOption, fuzzySortCurried } from "../../../hooks/useFuzzysort";
import { CRMTriageCaseAssignmentCard, TTriageCaseAssignmentCardAction } from "../CRMTriageCaseAssignmentCard/CRMTriageCaseAssignmentCard";
import { CRMFuzzySortSearchHighlight } from "../../Simple/CRMFuzzySortSearchHighlight/CRMFuzzySortSearchHighlight";
import { TTriageEmailAssignToCaseAndUserForm, TTriageEmailAssignToCaseForm, TTriageEmailAssignToCaseSearchForm, TCasesResultsForTriage } from "../../../../../domain/codecs/form/TriageForm";
import { CRMColors } from "../../../models/CRMColors";
import { CaseMemberRoleToCopyText } from "../../../../../domain/codecs/CaseMember";
import { CRMDualExpandingSearchInputs } from "../CRMDualExpandingSearchInputs/CRMDualExpandingSearchInputs";
import { TPixelSize } from "../../../models/StringLiterals";
import { WeightSemiBold } from "../../WeightSemiBold/WeightSemiBold";
import { CRMFilterSearchSelect } from "../CRMFilterSearchSelect/CRMFilterSearchSelect";

export type TAttachmentType = "enquiries" | "files";

export type TAssignmentProps<T> = { 
    form: T, 
    caseResult: TCasesResultsForTriage 
}; 

type TCRMTriageCaseAssignmentSearchProps = {
    searchStickyTopPosition?: TPixelSize;
    searchForm: TTriageEmailAssignToCaseSearchForm;
    onChangeSearchText: (searchText: string) => void;
    onAssignAndResolveWithNoAction: (props: TAssignmentProps<TTriageEmailAssignToCaseForm>) => void;
    onAssignToMe: (props: TAssignmentProps<TTriageEmailAssignToCaseAndUserForm>) => void;
    onAssignToStaff: (props: TAssignmentProps<TTriageEmailAssignToCaseAndUserForm>) => void;
    onAssignToHandler: (props: TAssignmentProps<TTriageEmailAssignToCaseAndUserForm>) => void;
};

export const CRMTriageCaseAssignmentSearch = (props: React.PropsWithChildren<TCRMTriageCaseAssignmentSearchProps>): JSX.Element => {

    const [assignSearchText, setAssignSearchText] = useState("");

    const getFilteredCaseAssignmentActions = (caseResult?: TCasesResultsForTriage): Array<TTriageCaseAssignmentCardAction> => {
        
        if (caseResult === undefined) {
            return [];
        }

        return pipe(
            getFilterableAssignmentActions(caseResult),
            array.map<TTriageCaseAssignmentCardAction, IFuzzySortOption<string, TTriageCaseAssignmentCardAction>>((action) => ({
                label: action.label,
                value: action.label,
                meta: action,
            })),
            fuzzySortCurried<string, TTriageCaseAssignmentCardAction>(assignSearchText),
            (fuzzySorted) => fuzzySorted.map((sortedResult) => ({
                ...sortedResult.obj.meta as TTriageCaseAssignmentCardAction,
                fuzzySortResult: sortedResult,
            })),
        );
    }
    
    const getFilterableAssignmentActions = (caseResult: TCasesResultsForTriage): Array<TTriageCaseAssignmentCardAction> => {
        return pipe(
            [
                [
                    {
                        backgroundColor: CRMColors.NEUTRAL_12,
                        label: "Assign to me",
                        onClick: () => props.onAssignToMe({
                            form: caseResult.assign_to_me_form, 
                            caseResult
                        })
                    },
                    {
                        backgroundColor: CRMColors.HIGHLIGHTS_POSITIVE_GREEN_12,
                        label: "Assign and resolve with no action!",
                        onClick: () => props.onAssignAndResolveWithNoAction({
                            form: caseResult.resolve_form, 
                            caseResult
                        }),
                    },
                ],
                caseResult.assign_to_case_handlers_form.map((form) => ({
                    backgroundColor: CRMColors.PRIMARY_12,
                    label: `Assign to: ${form.children.name}`,
                    onClick: () => props.onAssignToHandler({ form, caseResult }),
                })),
                caseResult.assign_to_other_staff_form.map((form) => ({
                    backgroundColor: CRMColors.HIGHLIGHTS_CALMING_PINK_10,
                    label: `Assign to: ${form.children.name}`,
                    onClick: () => props.onAssignToStaff({ form, caseResult }),
                })),
            ],
            array.flatten,
        )
    }
    
    const runAssignmentActionIfFilteredDownToIt = (results: Fuzzysort.KeyResults<IFuzzySortOption<string, TCasesResultsForTriage>>) => {
        const caseResults = results;
        const caseActions = getFilteredCaseAssignmentActions(caseResults[0]?.obj?.meta);

        if (caseResults.length === 1 && caseActions.length === 1) {
            caseActions[0].onClick();
        }
    }

    const caseToSearchLabel = (caseSearchResult: TCasesResultsForTriage): string => {
        const addresses = caseSearchResult.address.join(" ");
        const contactNames = caseSearchResult.contacts
            .map((contact) => `${contact.name} ${CaseMemberRoleToCopyText(contact.role)}`)
            .join(" ");

        return `${addresses} ${contactNames} ${caseSearchResult.klyant_matter_id}`;
    }


    return (
        <CRMFilterSearchSelect<TCasesResultsForTriage>
            searchFormStatus={props.searchForm.status}
            searchText={props.searchForm.edited.search_term}
            keepOriginalSearchResultsOrder={true}
            searchStickyTopPosition={props.searchStickyTopPosition || "0px"}
            noMatchesText={"No cases found"}
            onChangeSearchText={props.onChangeSearchText}
            renderCustomSearchInput={(searchText, onChangeSearchText, results) => (
                <CRMDualExpandingSearchInputs
                    firstInputValue={searchText}
                    firstInputPlaceholder="Find case"
                    onChangeFirstInput={onChangeSearchText}
                    secondInputValue={assignSearchText}
                    secondInputPlaceholder="Find action"
                    onChangeSecondInput={setAssignSearchText}
                    secondInputIcon="person"
                    secondInputIconHint="When typing, press TAB to access assign to person filter."
                    onPressEnter={() => runAssignmentActionIfFilteredDownToIt(results)}
                />
            )}
            options={
                props.searchForm.children.results.map((caseSearchResult) => ({
                    label: caseToSearchLabel(caseSearchResult),
                    value: caseSearchResult.id,
                    meta: caseSearchResult,
                    richLabelFormatting: (result, searchText) => (
                        <CRMTriageCaseAssignmentCard
                            key={caseSearchResult.id}
                            case={result.obj.meta as TCasesResultsForTriage}
                            actions={getFilteredCaseAssignmentActions(result.obj.meta as TCasesResultsForTriage)}
                            formatAddress={(address) => (
                                <CRMFuzzySortSearchHighlight
                                    text={address}
                                    searchText={searchText}
                                />
                            )}
                            formatKlyantMatterId={(klyantMatterId) => (
                                <CRMFuzzySortSearchHighlight
                                    text={klyantMatterId}
                                    searchText={searchText}
                                />
                            )}
                            formatClient={(clientName) => (
                                <CRMFuzzySortSearchHighlight
                                    text={clientName}
                                    searchText={searchText}
                                />
                            )}
                            formatAction={(actionName, actionResult) => (
                                <WeightSemiBold> 
                                    <CRMFuzzySortSearchHighlight
                                        text={actionName}
                                        searchText={assignSearchText}
                                    />
                                </WeightSemiBold>
                            )}
                        />
                    )
                }))
            }
        />
    );
};
