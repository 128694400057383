import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { array } from "../../shared/src/codecs/types/array";
import { boolean } from "../../shared/src/codecs/types/boolean";
import { decimal } from "../../shared/src/codecs/types/decimal";
import { integer } from "../../shared/src/codecs/types/integer";
import { required } from "../../shared/src/codecs/types/required";
import { string } from "../../shared/src/codecs/types/string";
import { uuid } from "../../shared/src/codecs/types/uuid";
import { Address1 } from "./Address";
import { CaseMember } from "./CaseMember";
import { CaseStatus } from "./Cases";
import { CaseSearchResultClient } from "./CaseSearchResultClient";
import { StaffUser } from "./User";

export const CaseSearchResult = 
    required({
        id: uuid(),
        addresses: array(Address1),
        client_users: array(CaseSearchResultClient),
        klyant_matter_id: string(),
        emails: array(CaseMember),
        case_handlers: array(StaffUser),
        is_dual_rep: boolean(),
        status: CaseStatus,
        search_matches_rank: decimal(),
        cases_status_rank: integer(),
    });

export type TCaseSearchResultCodec = typeof CaseSearchResult;
export type TCaseSearchResult = TTypeOfCodec<typeof CaseSearchResult>;