export type T = string;

export const fromAccountAndBucketNames = (accountName: string, bucketName: string): string =>
    `https://${accountName === "propertybid-local" ? "s3-proxy.sailhomes.local" : "s3.eu-west-2.amazonaws.com"}/${accountName}-${bucketName}`;

/* eslint-disable-next-line */
export enum bucketNames {
    LISTINGS_DOCUMENTS_BUCKET = "uploaded-documents",
    LISTINGS_IMAGES_BUCKET = "uploaded-images",
    SAIL_HOMES_CONTRACTS = "sh-contracts",
    SAIL_HOMES_CONTRACTS_AUTH_TO_SELL_VEHICLE = "sh-contracts-authority-to-sell-vehicle",
    UNIT_TEST_BUCKET = "unit-test-bucket",
    SAIL_LEGAL_DOCUMENTS = "sail-legal-documents-files",
    SAIL_LEGAL_EMAILS = "sail-legal-emails",
    SAIL_LEGAL_ID_CHECKS = "sail-legal-id-checks",
}
