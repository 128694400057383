import React from "react";
import { SpacingColumn } from "../../components/BuildingBlocks/SpacingColumn";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMCardOutside } from "../../components/CRMCardOutside/CRMCardOutside";
import { CRMListingSellerPartyFormContainer } from "../CRMListingSellerPartyFormContainer/CRMListingSellerPartyFormContainer";
import { CRMSpacing } from "../../models/CRMSpacing";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { array } from "fp-ts/lib";
import { pipe } from "fp-ts/lib/function";
import { FormStatus_highestPriority } from "../../../../shared/src/codecs/codec";

class CRMListingPageSellersContainer extends React.Component<React.PropsWithChildren<TContainerStateProps>> {
    public render(): JSX.Element {
        return (
            <SpacingRow spacing={CRMSpacing.X_LARGE} childSize="1fr 1fr">
                <SpacingColumn spacing={CRMSpacing.LARGE}>
                <CRMCardOutside
                    borderRounding="right"
                    shadow={true}
                >
                    <CRMPadding size="large">
                        <CRMAutosaveIndicatorWrap
                            status={pipe(
                                [
                                    ...pipe(
                                        this.props.state.forms.listing_page.data.output.listing_seller_users,
                                        array.map((form) => form.status),
                                    ),
                                    ...pipe(
                                        this.props.state.forms.listing_page.data.output.listing_seller_users,
                                        array.map((form) => form.children.phone_numbers.map((ph) => ph.status)),
                                        array.flatten,
                                    )
                                ],
                                FormStatus_highestPriority,
                            )}
                        >
                            <CRMSpacingColumn spacing="large">
                                <CRMTitleSection>
                                    Sellers
                                </CRMTitleSection>
                                <CRMListingSellerPartyFormContainer
                                    state={this.props.state}
                                    dispatch={this.props.dispatch}
                                />
                            </CRMSpacingColumn>
                        </CRMAutosaveIndicatorWrap>
                    </CRMPadding>
                </CRMCardOutside>
                </SpacingColumn>
            </SpacingRow>
        );
    }
}

export default CRMListingPageSellersContainer;
