import React, { useEffect } from "react";
import { TBlockContainerStateProps } from "../../state/TBlockContainerStateProps";
import { array } from "fp-ts";
import { CRMLegalCaseContractsSections, CRMLegalFormSections, TCRMLegalCaseContractsSections } from "../../models/CRMFormSections";
import { arrayOfStringsToPipeString } from "../../../../shared/src/util";
import { MinHeight } from "../../components/BuildingBlocks/MinHeight";
import { SpacingRow } from "../../components/BuildingBlocks/SpacingRow";
import { CRMBlockScrollCacheView } from "../../components/CRM/CRMBlock/CRMBlockViews/CRMBlockViews";
import { CRMBottomControlBar } from "../../components/CRMBottomControlBar/CRMBottomControlBar";
import { CRMCardOutside } from "../../components/CRMCardOutside/CRMCardOutside";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMBlockFilterCheckboxes } from "../../components/Simple/CRMBlockFilterCheckboxes/CRMBlockFilterCheckboxes";
import { CRMPadding } from "../../components/Simple/CRMPadding/CRMPadding";
import CRMLegalCaseDetailsContractDetailsContainer from "../CRMLegalCaseDetailsContractDetailsContainer/CRMLegalCaseDetailsContractDetailsContainer";
import CRMLegalCaseDetailsEstateAgentDetailsContainer from "../CRMLegalCaseDetailsEstateAgentDetailsContainer/CRMLegalCaseDetailsEstateAgentDetailsContainer";
import CRMLegalCaseDetailsOtherSideClientContainer from "../CRMLegalCaseDetailsOtherSideClientContainer/CRMLegalCaseDetailsOtherSideClientContainer";
import CRMLegalCaseDetailsOtherSideConveyancerContainer from "../CRMLegalCaseDetailsOtherSideConveyancerContainer/CRMLegalCaseDetailsOtherSideConveyancerContainer";
import CRMLegalCaseDetailsPropertyDetailsContainer from "../CRMLegalCaseDetailsPropertyDetailsContainer/CRMLegalCaseDetailsPropertyDetailsContainer";
import { pipe } from "fp-ts/lib/function";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import CRMLegalCaseContractMilestonesContainer from "../CRMLegalCaseContractMilestonesContainer/CRMLegalCaseContractMilestonesContainer";
import { CRMFormButton } from "../../components/CRMFormButton/CRMFormButton";
import { CRMButtonPrimary } from "../../components/CRMButtonPrimary/CRMButtonPrimary";
import CRMLegalCaseDetailsContractClientConfigurationContainer from "../CRMLegalCaseDetailsContractClientConfigurationContainer/CRMLegalCaseDetailsContractClientConfigurationContainer";
import CRMLegalCaseDetailsContractDocumentGenerationContainer from "../CRMLegalCaseDetailsContractDocumentGenerationContainer/CRMLegalCaseDetailsContractDocumentGenerationContainer";
import { Background } from "../../components/BuildingBlocks/Background";
import { CRMSpacing } from "../../models/CRMSpacing";

const canShowEstateAgentDetails = (props: TContainerStateProps) =>
    (
        props.state.forms.contracts_block.children.details.edited.transaction_type === "sale"
        || props.state.forms.contracts_block.children.details.edited.transaction_type === "purchase"
    )
    && props.state.forms.contracts_block.children.case_status !== "quote";

const isShowingOtherSideConveyancerDetails = (props: TBlockContainerStateProps) =>
    isContractSectionSelected("other_side_conveyancer_details", props) 
    && canShowOtherSideConveyancerDetails(props)
;

const isShowingEstateAgentDetails = (props: TBlockContainerStateProps) =>
    isContractSectionSelected("estate_agent_details", props)
    && canShowEstateAgentDetails(props)
;

const isShowingOtherSideClientDetails = (props: TBlockContainerStateProps) =>
    isContractSectionSelected("other_side_client_details", props) 
    && canShowOtherSideClientDetails(props)
;

const isShowingMilestones = (props: TBlockContainerStateProps) =>
    isContractSectionSelected("milestones", props)
    && canShowMilestones(props)
;

const canShowOtherSideConveyancerDetails = (props: TContainerStateProps) =>
    props.state.forms.contracts_block.children.case_status !== "quote"
    && props.state.forms.contracts_block.children.details.edited.transaction_type !== "remortgage";

const canShowOtherSideClientDetails = (props: TContainerStateProps) =>
    props.state.forms.contracts_block.children.case_status !== "quote"
    && props.state.forms.contracts_block.children.details.edited.transaction_type !== "remortgage";

const canShowMilestones = (props: TContainerStateProps) =>
    props.state.forms.contracts_block.children.details.edited.transaction_type === "sale";

const isContractSectionSelected = (section: TCRMLegalCaseContractsSections[number], props: TBlockContainerStateProps): boolean => 
    {
        const block = props.state.routes.blockParams.VIEW_CRM_LEGAL_CASE[props.index];
        return CRMLegalCaseContractsSections.isDetailsSectionSelected(
            "visible_sections" in block.meta ? block.meta.visible_sections : undefined,
            section
        );
    };

export const CRMLegalCaseContractsContainer = React.memo((props: React.PropsWithChildren<TBlockContainerStateProps>): JSX.Element => {

    useEffect(
        () => {
            if (!props.isFirstLoad) {
                props.dispatch({
                    type: "LEGAL_CASE_CONTRACTS_BLOCK_VIEW",
                    payload: null,
                })
            }
        },
        []
    );

    const getVisibleSections = () => {
        const block = props.state.routes.blockParams.VIEW_CRM_LEGAL_CASE[props.index];
        return "visible_sections" in block.meta ? block.meta.visible_sections : undefined
    };

    return <CRMBlockScrollCacheView isShowing={true}>
        <CRMCardOutside borderRounding="right">
            <MinHeight height="100vh">
                <CRMPadding size="large">
                    <CRMSpacingColumn spacing="large">
                        {/* SECTION - MILESTONES */}
                        {isShowingMilestones(props) &&
                            <CRMLegalCaseContractMilestonesContainer {...props} />
                        }
                        {/* SECTION - PROPERTY DETAILS */}
                        {isContractSectionSelected("property_details", props) && 
                            <CRMLegalCaseDetailsPropertyDetailsContainer {...props} />
                        }
                        {/* SECTION - ESTATE AGENT DETAILS */}
                        {isShowingEstateAgentDetails(props) && 
                            <CRMLegalCaseDetailsEstateAgentDetailsContainer {...props} />
                        }
                        {/* SECTION - CONTRACT DETAILS */}
                        {isContractSectionSelected("contract_details", props) && 
                            <CRMLegalCaseDetailsContractDetailsContainer {...props} />
                        }
                        {/* SECTION - OTHER SIDE CONVEYANCER DETAILS */}
                        {isShowingOtherSideConveyancerDetails(props) &&
                            <CRMLegalCaseDetailsOtherSideConveyancerContainer {...props} />
                        }
                        {/* SECTION - OTHER SIDE CLIENT DETAILS */}
                        {isShowingOtherSideClientDetails(props) &&
                            <CRMLegalCaseDetailsOtherSideClientContainer {...props} />
                        }
                        {/* SECTION - CLIENT CONFIGURATION */}
                        {isContractSectionSelected("client_configuration", props) &&
                            <CRMLegalCaseDetailsContractClientConfigurationContainer {...props} />
                        }
                        {/* SECTION - DOCUMENT GENERATION */}
                        {isContractSectionSelected("document_generation", props) &&
                            <CRMLegalCaseDetailsContractDocumentGenerationContainer {...props} />
                        }
                    </CRMSpacingColumn>
                </CRMPadding>
            </MinHeight>
        </CRMCardOutside>

        {/* CONTROL BAR WITH FILTER */}
        <CRMBottomControlBar>
            <SpacingRow justifyContent="end">
                
                <Background padding={`0px ${CRMSpacing.SMALL}`}>
                    <CRMFormButton
                        formStatus={props.state.forms.contracts_block.children.create_dcp_form.status}
                        ButtonElement={CRMButtonPrimary}
                        label="Create Draft Contract Pack"
                        onClick={() => props.dispatch({
                            type: "LEGAL_CONTRACT_CREATE_DCP",
                        })}
                        enabledOnUntouched={true}
                    />
                </Background>

                <CRMBlockFilterCheckboxes
                    options={pipe(
                        CRMLegalCaseContractsSections.list,
                        array.filter((key) => key === "estate_agent_details" ? canShowEstateAgentDetails(props) : true),
                        array.filter((key) => key === "other_side_conveyancer_details" ? canShowOtherSideConveyancerDetails(props) : true),
                        array.filter((key) => key === "other_side_client_details" ? canShowOtherSideClientDetails(props) : true),
                        array.filter((key) => key === "milestones" ? canShowMilestones(props) : true),
                    )}
                    optionsSelected={CRMLegalFormSections.sectionsStringToArray(getVisibleSections())}
                    onSelectedChange={(payload) => props.dispatch({
                        type: "UPDATE_LEGAL_CASE_VISIBLE_SECTIONS_FILTER",
                        payload: {sections: arrayOfStringsToPipeString(payload), index: props.index}
                    })}
                />
            </SpacingRow>
        </CRMBottomControlBar>
    </CRMBlockScrollCacheView>
});
